import { theme } from 'lib'
import styled, { css } from 'styled-components'
import { StyledProps } from './Typography.types'

const colorMixin = (defaultColor: string) => css`
  color: ${(props: StyledProps) => props.color || defaultColor};
`

export const H1 = styled.h1<StyledProps>`
  font-family: ${theme.fontFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 2.2rem;
  margin: 0;
  margin-bottom: 0.5rem;
  ${colorMixin(theme.colors.black)}
  text-align: ${(p) => p.align};
`

export const H2 = styled.h2<StyledProps>`
  font-family: ${theme.fontFamily};
  color: ${theme.colors.black};
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.6rem;
  margin: 0.5rem 0;
  ${colorMixin(theme.colors.black)}
  text-align: ${(p) => p.align};
`

export const H3 = styled.h3<StyledProps>`
  font-family: ${theme.fontFamily};
  color: ${theme.colors.black};
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  margin: 0.25rem 0;
  ${colorMixin(theme.colors.black)}
  text-align: ${(p) => p.align};
`

export const Subtitle = styled.p<StyledProps>`
  font-family: ${theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0;
  ${colorMixin(theme.colors.gray2)}
  text-align: ${(p) => p.align};
`

export const Text = styled.p<StyledProps>`
  font-family: ${theme.fontFamily};
  font-style: normal;
  font-weight: ${(p) => (p.highlight ? 600 : 400)};
  font-size: ${(p) => {
    if (p.size === 'small') return '1.2rem'
    if (p.size === 'big') return '1.6rem'
    return '1.4rem'
  }};
  line-height: ${(p) => {
    if (p.size === 'small') return '2.0rem'
    if (p.size === 'big') return '2.2rem'
    return '2.4rem'
  }};
  margin: 0.25rem 0;
  ${colorMixin(theme.colors.black)}
  text-align: ${(p) => p.align};
`

export const Highlight = styled.span`
  color: ${theme.colors.black};
  font-weight: 600;
`

import { ReactElement, ReactNode } from 'react'
import { Option } from 'fp-ts/lib/Option'
import { ContractStepStatus } from 'types'

export type CustomStepConfig = {
  borderColor: Option<string>
  color: string
  ligatureColor: string
  icon: Option<string>
}

export type TimelineOrientation = 'vertical' | 'horizontal'

export type CustomTimelineStep = {
  type: 'custom'
  config: CustomStepConfig
}

export type TimelineVariant = ContractStepStatus | CustomTimelineStep

export type TimelineItemProps = {
  variant: TimelineVariant
  orientation?: TimelineOrientation
  children?: ReactNode
}

export const Custom = (config: CustomStepConfig): CustomTimelineStep => ({
  type: 'custom',
  config,
})

export type TimelineProps = {
  orientation?: TimelineOrientation
  children: ReactElement<TimelineItemProps>[] | ReactElement<TimelineItemProps>
}

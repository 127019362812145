import { EMPTY_ILLUSTRATION } from 'assets'
import { Typography } from 'ui'

import * as S from './NotFound.styled'

const NotFound = () => (
  <S.Wrapper>
    <img src={EMPTY_ILLUSTRATION} />
    <Typography variant='h2'>Página não encontrada</Typography>
  </S.Wrapper>
)

export default NotFound

import styled from 'styled-components'
import { motion } from 'framer-motion'
import { transparentize } from 'polished'
import { theme, media } from 'lib'
import { Card } from 'ui/Card'

const Overlay = motion(styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${transparentize(0.5, theme.colors.black)};
  z-index: ${theme.zIndex.top};
  padding: 0 ${theme.spacing[3]};
`)

const Dialog = styled(Card)`
  position: relative;
  width: 100%;
  min-height: 20rem;
  ${media.greaterThan('tablet')`
    max-width: calc(${theme.constants.wrapperMaxWidth} - ${theme.spacing[6]} );
  `}
`

const CloseButton = styled.button`
  border: 0;
  background: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  font-size: 0;
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
`

export { Overlay, Dialog, CloseButton }

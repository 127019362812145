import { ChangeEvent } from 'react'
import { Typography } from 'ui'
import Box from '@material-ui/core/Box'
import * as S from './Checkbox.styled'

type Props = {
  description: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  defaultChecked?: boolean
}

const Checkbox = ({
  description,
  onChange,
  checked,
  defaultChecked,
}: Props) => (
  <S.Wrapper>
    <S.Input
      type='checkbox'
      onChange={onChange}
      checked={checked}
      defaultChecked={defaultChecked}
    />
    <Box ml={2}>
      <Typography size='small'>{description}</Typography>
    </Box>
  </S.Wrapper>
)

export { Checkbox }

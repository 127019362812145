import styled from 'styled-components'
import { theme } from 'lib'
import { StyledProps } from './Divider.types'

const Divider = styled.div<StyledProps>`
  width: 100%;
  margin: ${theme.spacing[1]} 0;
  border: 1px solid ${(props) => props.color};
`

export { Divider }

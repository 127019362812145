import Icon from '@mdi/react'
import React from 'react'
import { Badge, Card, LabeledValue, Typography } from 'ui'
import { mdiDomain } from '@mdi/js'

import { theme } from 'lib'
import { ContractStatus } from 'types'
import * as S from './Contract.styled'

export type Props = {
  estateName: string
  block: string
  unit: string
  status: ContractStatus
  id: string
  showStatus: boolean
  footer?: React.ReactNode
  children?: React.ReactNode
}

const labeledStatus = {
  DOCUMENTATION: 'DOCUMENTAÇÃO',
  PRE_ANALYSIS: 'PRÉ-ANÁLISE',
}

const Contract = ({
  estateName,
  unit,
  block,
  status,
  showStatus,
  footer,
  children,
}: Props) => (
  <Card>
    <S.Status>
      {showStatus && <Badge size='medium'>{labeledStatus[status]}</Badge>}
    </S.Status>
    <S.Heading>
      <S.IconWrapper>
        <Icon color={theme.colors.blackBlue} size='1.8rem' path={mdiDomain} />
      </S.IconWrapper>
      <Typography variant='h3'>{estateName}</Typography>
    </S.Heading>
    <S.Row>
      <LabeledValue
        labelSize='small'
        captionSize='small'
        variant='caption'
        label='Unidade'
      >
        {unit}
      </LabeledValue>
      <LabeledValue
        labelSize='small'
        captionSize='small'
        captionColor={theme.colors.blackGreen}
        variant='caption'
        label='Bloco'
      >
        {block}
      </LabeledValue>
    </S.Row>
    {children}
    {footer && <S.Footer>{footer}</S.Footer>}
  </Card>
)

export default Contract

import Loader from 'react-content-loader'
import { theme } from 'lib'
import Box from '@material-ui/core/Box'

const Texts = () => (
  <Loader
    speed={2}
    width={100}
    height={86}
    backgroundColor={theme.colors.loaderBackground}
    foregroundColor={theme.colors.loaderForeground}
    style={{ width: '100%', height: '86px' }}
  >
    <rect
      x='0'
      y='0'
      rx={theme.radius.small}
      ry={theme.radius.small}
      width='70%'
      height='3.5rem'
    />
    <rect
      x='0'
      y='46'
      rx={theme.radius.small}
      ry={theme.radius.large}
      width='100%'
      height='3.5rem'
    />
  </Loader>
)

const FinancingSection = () => (
  <Loader
    speed={2}
    width={100}
    height={336}
    backgroundColor={theme.colors.loaderBackground}
    foregroundColor={theme.colors.loaderForeground}
    style={{ width: '100%', height: '336' }}
  >
    <rect
      x='0'
      y='0'
      rx={theme.radius.xSmall}
      ry={theme.radius.xSmall}
      width='70%'
      height='2.4rem'
    />
    <rect
      x='0'
      y='36'
      rx={theme.radius.small}
      ry={theme.radius.large}
      width='100%'
      height='300'
    />
  </Loader>
)

const SimulationsSection = () => (
  <Loader
    speed={2}
    width={100}
    height={436}
    backgroundColor={theme.colors.loaderBackground}
    foregroundColor={theme.colors.loaderForeground}
    style={{ width: '100%', height: '436' }}
  >
    <rect
      x='0'
      y='0'
      rx={theme.radius.xSmall}
      ry={theme.radius.xSmall}
      width='70%'
      height='2.4rem'
    />
    <rect
      x='0'
      y='36'
      rx={theme.radius.small}
      ry={theme.radius.large}
      width='100%'
      height='400'
    />
  </Loader>
)

const ContentLoader = () => (
  <div data-testid='content-loader'>
    <Box mb={5}>
      <Texts />
    </Box>

    <Box mb={5}>
      <FinancingSection />
    </Box>
    <Box mb={5}>
      <SimulationsSection />
    </Box>
  </div>
)

export default ContentLoader

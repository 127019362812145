import { theme } from 'lib'
import styled from 'styled-components'
import Slider from '@material-ui/core/Slider'
import Box from '@material-ui/core/Box'

const StyledSlider = styled(Slider)`
  && {
    color: ${theme.colors.primary};
    margin-right: ${theme.spacing[2]};
    .MuiSlider-valueLabel {
      color: transparent;
      top: 2.4rem;
      left: calc(-50% + 0.2rem);
      > span > span {
        font-family: ${theme.fontFamily};
        font-size: 1.4rem;
        color: ${theme.colors.primary};
      }
    }
    .MuiSlider-thumb {
      width: 1.8rem;
      height: 1.8rem;
      margin-top: -0.6rem;
      margin-left: -0.6rem;
    }
    .Mui-disabled {
      color: ${theme.colors.gray2};
      > span > span > span {
        color: ${theme.colors.black};
      }
    }
    .MuiSlider-track {
      height: 4px;
      border-radius: 4px;
    }
    .MuiSlider-rail {
      color: ${theme.colors.gray1};
      height: 4px;
      border-radius: ${theme.radius.xSmall};
    }
  }
`

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
`

export { StyledSlider, Wrapper }

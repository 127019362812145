import styled from 'styled-components'
import { theme } from 'lib'
import { Typography } from 'ui'

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 ${theme.spacing[7]};
`

export const ErrorTypography = styled(Typography)`
  font-size: 1.2rem;
`

import styled from 'styled-components'
import { media, theme } from 'lib'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const Background = styled.div`
  background: ${theme.colors.blackBlue};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 12rem;
  max-width: ${theme.constants.wrapperMaxWidth};
  z-index: ${theme.zIndex.hidden};

  ${media.greaterThan('tablet')`
    max-width: ${theme.constants.wrapperMaxWidth};
    left: 50%;
    margin-left: calc(-${theme.constants.wrapperMaxWidth} / 2 );
    border-radius: ${theme.radius.xSmall} ${theme.radius.xSmall} 0 0;
  `};
`

const ContractsList = styled(motion.ul)`
  margin: ${theme.spacing[3]} 0 0 0;
  padding: 0;
  list-style: none;

  > li {
    margin-bottom: ${theme.spacing[2]};
  }
`

const CardLink = styled(Link)`
  text-decoration: none;
`

export { Background, ContractsList, CardLink }

import styled from 'styled-components'
import { media, theme } from 'lib'
import { Link } from 'react-router-dom'

const Header = styled.div`
  display: flex;
  align-items: center;
  ${media.lessThan('smallPhone')`
    flex-direction: column;
  `}
`

const Title = styled.h2`
  font-family: ${theme.fontFamily};
  font-size: 1.6rem;
  color: ${theme.colors.primary};

  ${media.greaterThan('smallPhone')`
    margin-left: ${theme.spacing[2]};
  `}
`

const CardLink = styled(Link)`
  text-decoration: none;
`

export { Header, Title, CardLink }

import Icon from '@mdi/react'
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { theme } from 'lib'

import * as S from './ToggleVisibility.styled'

type Props = {
  visible: boolean
  onClick: () => void
}

const ToggleVisibility = ({ visible, onClick }: Props) => (
  <S.Button onClick={onClick}>
    <Icon
      path={visible ? mdiEye : mdiEyeOff}
      size='2.6rem'
      color={theme.colors.gray2}
    />
  </S.Button>
)

export { ToggleVisibility }

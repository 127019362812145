import { theme } from 'lib'
import styled, { css } from 'styled-components'
import { StyledProps } from './Badge.types'

export const Badge = styled.span<StyledProps>`
  padding: 0.2rem 1rem;
  display: flex;
  align-items: center;
  font-family: ${theme.fontFamily};
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -0.03em;
  border-radius: ${theme.radius.xSmall};

  ${(props) =>
    props.variant === 'default' &&
    css`
      background: ${props.color};
      color: ${theme.colors.white};
    `}

  ${(props) =>
    props.variant === 'outlined' &&
    css`
      color: ${props.color};
      border: solid 1px ${props.color};
      background-color: transparent;
    `}
`

import { theme } from 'lib'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { StyledStatusProps } from './CategoryCard.types'

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${theme.spacing[2]};
`

const Status = styled.div<StyledStatusProps>`
  position: absolute;
  width: 3.2rem;
  height: 3.2rem;
  background: ${(p) => colorByStatus[p.status.tag]};
  top: 50%;
  margin-top: -1.6rem;
  left: -1.6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CardLink = styled(Link)`
  text-decoration: none;
`

export const Counter = styled.span`
  width: 2rem;
  height: 2rem;
  font-family: ${theme.fontFamily};
  color: ${theme.colors.white};
  background: ${theme.colors.gray2};
  border-radius: 50%;
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 500;
`

const colorByStatus = {
  PENDING: theme.colors.orange,
  APPROVED: theme.colors.primary,
  REFUSED: theme.colors.red,
  WAITING: '',
}

export { CardLink, Row, Status }

import { useRef, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import {
  Button,
  Card,
  Checkbox,
  DiscreteSlider,
  ErrorDialog,
  TextInput,
  Typography,
} from 'ui'
import { theme } from 'lib'
import { DATE_ICON, FOUNDATION_ICON, GROUP_ICON, MONEY_ICON } from 'assets'
import ContentLoader from 'pages/Simulations/ContentLoader/ContentLoader'
import { Controller } from 'react-hook-form'
import * as S from './ChangeParameters.styled'
import { useChangeParameters } from './useChangeParameters'

type Props = {
  onClick: () => void
}

const ChangeParameters = ({ onClick }: Props) => {
  const {
    proponentYear,
    isUpdating,
    isLoading,
    isError,
    setIsError,
    handleSubmit,
    onSubmit,
    control,
    handleProponentYears,
    simulationParams,
    refetch,
  } = useChangeParameters(onClick)
  const incomeMaxValue = ({ value }: { value: number }) => value <= 999999.99
  const amortizationMaxValue = ({ value }: { value: number }) =>
    value <= 999999999.99
  const inputRef = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    refetch()
  }, [simulationParams])

  return (
    <>
      {isLoading && <ContentLoader />}
      {isUpdating && <ContentLoader />}

      {!isUpdating && (
        <Box mt={3} mb={2}>
          <Typography variant='h2' align='left'>
            Para alcançar o financiamento necessário, você pode
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box my={3}>
              <Card minWidth='25.0rem'>
                <img src={GROUP_ICON} />
                <Box my={1.5}>
                  <Typography variant='h3'>
                    Somar renda com mais gente
                  </Typography>
                </Box>
                <Box mb={3}>
                  <Typography color={theme.colors.gray2}>
                    Família, amigos... é possível juntar a renda de outras
                    pessoas com a sua, e elas nem precisam morar junto com você.
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography highlight>
                    Atualizar a soma das rendas para
                  </Typography>
                </Box>
                <Controller
                  name='incomePrimary'
                  control={control}
                  defaultValue={''}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      onChange={(event) => onChange(event.currentTarget.value)}
                      isAllowed={incomeMaxValue}
                      value={value}
                    />
                  )}
                />
              </Card>
            </Box>
            <Box mb={3}>
              <Card minWidth='25.0rem'>
                <img src={FOUNDATION_ICON} />
                <Box my={1.5}>
                  <Typography variant='h3'>Aumentar a amortização</Typography>
                </Box>
                <Box mb={3}>
                  <Typography color={theme.colors.gray2}>
                    Pagando um valor maior direto para o vendedor, sobra menos
                    para parcelar. Assim, você pode conseguir financiar tudo o
                    que precisa. <br /> <br /> Você pode somar aqui o resgate do
                    seu FGTS.
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography highlight>
                    Atualizar a amortização para
                  </Typography>
                </Box>
                <Controller
                  name='amortization'
                  control={control}
                  defaultValue={''}
                  render={({
                    field: { onChange, value },
                    fieldState: { error, invalid },
                  }) => (
                    <>
                      <TextInput
                        onChange={({ currentTarget }) =>
                          onChange(currentTarget.value)
                        }
                        isAllowed={amortizationMaxValue}
                        value={value}
                        error={invalid}
                        getInputRef={inputRef}
                        setFocus={invalid && inputRef.current?.focus()}
                      />
                      {error && (
                        <S.ErrorTypography color={theme.colors.red}>
                          {error.message}
                        </S.ErrorTypography>
                      )}
                    </>
                  )}
                />
              </Card>
            </Box>
            <Box mb={3}>
              <Card minWidth='25.0rem'>
                <img src={DATE_ICON} />
                <Box my={1.5}>
                  <Typography variant='h3'>Aumentar o prazo</Typography>
                </Box>
                <Box mb={3}>
                  <Typography color={theme.colors.gray2}>
                    Quanto mais tempo você leva para terminar de pagar, menor é
                    o valor das suas parcelas. Uma folga no orçamento é sempre
                    bem-vinda.
                  </Typography>
                </Box>
                <Typography highlight>Atualizar o prazo para</Typography>
                <Box mb={2}>
                  <Controller
                    name='paymentDeadline'
                    control={control}
                    defaultValue={1}
                    render={({ field: { onChange, value } }) => (
                      <DiscreteSlider
                        onChange={(_, value) => {
                          onChange(value)
                          handleProponentYears(value as number)
                        }}
                        value={value}
                        min={1}
                        max={35}
                        label='anos'
                      />
                    )}
                  />
                </Box>
                <Box mb={3}>
                  <Typography size='small' color={theme.colors.primary}>
                    Esse prazo limita a idade do proponente mais velho a{' '}
                    {proponentYear} anos
                  </Typography>
                </Box>
              </Card>
            </Box>
            <Box mb={5}>
              <Card minWidth='25.0rem'>
                <img src={MONEY_ICON} />
                <Box my={1.5}>
                  <Typography variant='h3'>Despesas cartorárias</Typography>
                </Box>
                <Box mb={3}>
                  <Typography color={theme.colors.gray2}>
                    São taxas e impostos obrigatórios, que somam 5% do valor do
                    imóvel. <br /> <br /> Caso você escolha não incluí-las, deve
                    pagar esse valor com recursos próprios, assim que o contrato
                    do financiamento for emitido.
                  </Typography>
                </Box>
                <Controller
                  name='includeNotorialCosts'
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      description='Incluir despesas cartorárias no financiamento'
                      checked={value}
                      onChange={({ target }) => onChange(target.checked)}
                    />
                  )}
                />
              </Card>
            </Box>
            <S.ButtonWrapper>
              <Button type='submit' block>
                Atualizar
              </Button>
            </S.ButtonWrapper>
          </form>

          <ErrorDialog
            visible={isError === 'error'}
            onClose={() => setIsError('initial')}
            onConfirm={() => setIsError('initial')}
            title='Algo saiu errado'
            description='Não foi possível alterar os parâmetros'
          ></ErrorDialog>
        </Box>
      )}
    </>
  )
}

export { ChangeParameters }

import { theme } from 'lib'
import * as S from './Divider.styled'

type Props = {
  color?: string
}

const Divider = ({ color }: Props) => (
  <S.Divider color={color || theme.colors.gray1} />
)

export { Divider }

import { Typography } from 'ui'
import Box from '@material-ui/core/Box'
import { NavigationRoute } from 'ui/BottomNav/useBottomNav'
import { fadeIn } from 'lib'
import { useContracts } from 'client'
import { isSome } from 'fp-ts/lib/Option'

import { Contract } from 'ui/Contract'
import { CONTRACT, PRE_ANALYSIS, SIMULATIONS } from 'routes'
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded'
import * as Loader from './ContentLoader'
import * as S from './ContractsModal.styled'

type Props = {
  route?: NavigationRoute
  onClick?: () => void
}

export const ContractsModal = ({ route, onClick }: Props) => {
  const { isFetching: isFetchingContracts, contracts } = useContracts()

  return (
    <>
      {isFetchingContracts && <Loader.ContractsSection />}
      {isSome(contracts) && (
        <>
          <Box display='flex' flexDirection='column'>
            <Typography variant='h2' align='left'>
              Qual financiamento deseja consultar?
            </Typography>
          </Box>
          <S.ContractsList
            animate='visible'
            initial='hidden'
            variants={fadeIn({ delay: 0.9 })}
            data-testid='modal-contracts-list'
          >
            {contracts.value.map((contract) => (
              <li key={contract.id}>
                <S.CardLink
                  to={
                    route === 'analysis'
                      ? `${CONTRACT.replace(':id', contract.id)}${PRE_ANALYSIS}`
                      : `${CONTRACT.replace(':id', contract.id)}${SIMULATIONS}`
                  }
                  onClick={onClick}
                >
                  <Contract
                    id={contract.id}
                    key={contract.id}
                    estateName={contract.property.estate.name}
                    block={contract.property.block}
                    unit={contract.property.unit}
                    status={contract.status}
                    showStatus={false}
                  >
                    <S.ArrowIcon>
                      <KeyboardBackspaceRoundedIcon />
                    </S.ArrowIcon>
                  </Contract>
                </S.CardLink>
              </li>
            ))}
          </S.ContractsList>
        </>
      )}
    </>
  )
}

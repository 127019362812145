import styled, { css } from 'styled-components'
import { theme } from 'lib'
import { LabelProps, ValueProps, WrapperProps } from './LabeledValue.types'

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing[1]};
  ${(props) =>
    props.variant === 'caption' &&
    css`
      position: relative;
      padding-left: 1.2rem;
      &:before {
        position: absolute;
        content: ' ';
        width: 3px;
        border-radius: 2px;
        height: 90%;
        background-color: ${props.captionColor};
        left: 0;
        top: 0;
      }
    `};
`

export const Label = styled.p<LabelProps>`
  font-weight: 400;
  margin: 0 0 0.15rem 0;
  color: ${(p) => p.color};
  font-family: ${theme.fontFamily};
  white-space: pre-wrap;
  font-size: ${(props) =>
    ({
      small: '1.2rem',
      medium: '1.6rem',
    }[props.size])};
`

export const Value = styled.span<ValueProps>`
  font-weight: 600;
  margin: 0;
  color: ${theme.colors.black};
  font-family: ${theme.fontFamily};
  font-size: ${(props) =>
    ({
      small: '1.4rem',
      medium: '1.6rem',
    }[props.size])};
`

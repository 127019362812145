import React from 'react'
import { HTMLMotionProps, motion, Variants } from 'framer-motion'

import * as S from './Typography.styled'

type Props = HTMLMotionProps<'h1'> & {
  variant?: 'h1' | 'h2' | 'h3' | 'text' | 'subtitle'
  variants?: Variants
  children: React.ReactNode
  color?: string
  align?: 'center' | 'left' | 'right'
  size?: 'small' | 'regular' | 'big'
  highlight?: boolean
}

const components = {
  h1: motion(S.H1),
  h2: motion(S.H2),
  h3: motion(S.H3),
  subtitle: motion(S.Subtitle),
  text: motion(S.Text),
}

const Typography = ({
  children,
  variant = 'text',
  color,
  variants,
  animate,
  initial,
  className,
  align = 'left',
  highlight = false,
  size = 'regular',
}: Props) => {
  const Component = components[variant]
  return (
    <Component
      align={align}
      variants={variants}
      className={className}
      animate={animate}
      initial={initial}
      color={color}
      highlight={highlight}
      size={size}
    >
      {children}
    </Component>
  )
}

Typography.Highlight = S.Highlight

export { Typography }

import styled from 'styled-components'
import { Typography } from 'ui/Typography'
import { StyledArrowProps } from './Accordion.types'

const Wrapper = styled.div``

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled(Typography)`
  font-weight: bold;
  position: relative;
`

const ArrowButton = styled.button<StyledArrowProps>`
  border: 0;
  background: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  font-size: 0;
  transform: rotate(${(p) => (p.isOpen ? '180deg' : '0deg')});
  transition: transform 200ms;
`

export { Wrapper, Header, ArrowButton, Title }

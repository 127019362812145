import styled from 'styled-components'

const Button = styled.button`
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
`

export { Button }

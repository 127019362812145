import { EMPTY_ILLUSTRATION } from 'assets'
import { theme } from 'lib'
import { Button, Typography } from 'ui'
import Box from '@material-ui/core/Box'

import * as S from './ExpiredSession.styled'

const { REACT_APP_BOT_WHATSAPP_LINK: BOT_LINK = '' } = process.env

const ExpiredSession = () => (
  <S.Wrapper>
    <img
      src={EMPTY_ILLUSTRATION}
      alt='A imagem mostra um parque com várias árvores e um banco.'
    />
    <Box mx={3} mt={2} mb={3}>
      <Typography align='center' variant='h2' color={theme.colors.black}>
        Solicite um novo link a Simone para acessar a sua Wallet Soma+.
      </Typography>
    </Box>
    <Button.Link external to={BOT_LINK}>
      Solicitar novo link
    </Button.Link>
  </S.Wrapper>
)

export default ExpiredSession

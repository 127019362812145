import { PDF_THUMBNAIL } from 'assets'
import { ReactNode, useMemo } from 'react'
import { FileType } from 'types'
import Box from '@material-ui/core/Box'
import * as S from './DocumentCard.styled'

type Props = {
  url: string
  fileType: FileType
  name: string
  children?: ReactNode
  clickable?: boolean
}

export type CardProps = {
  clickable: 1 | 0
}

const DocumentCard = ({ name, url, fileType, clickable, children }: Props) => {
  const thumbnailUrl = useMemo(
    () => (fileType === 'pdf' ? PDF_THUMBNAIL : url),
    [url, fileType],
  )
  return (
    <S.Card clickable={clickable ? 1 : 0}>
      <Box display='flex' alignItems='center'>
        <S.Thumbnail src={thumbnailUrl} />
        <S.TruncatedName>{name}</S.TruncatedName>
      </Box>
      {children}
    </S.Card>
  )
}
export { DocumentCard }

import { useState } from 'react'
import { useContracts } from 'client'
import { Contract } from 'types'
import { pipe } from 'fp-ts/function'
import { map, Option } from 'fp-ts/lib/Option'

type Navigation = { mode: 'single'; contractId: string } | { mode: 'multiple' }

export type NavigationRoute = 'analysis' | 'simulations'

export type ModalConfig = {
  visible: boolean
  route?: NavigationRoute
}

const initialConfig = {
  visible: false,
}

const useBottomNav = () => {
  const { isFetching: isFetchingContracts, contracts } = useContracts()
  const [modal, setModal] = useState<ModalConfig>(initialConfig)

  const openModal = (route: NavigationRoute) =>
    setModal({
      visible: true,
      route,
    })

  const closeModal = () => setModal(initialConfig)

  return {
    openModal,
    closeModal,
    modal,
    isFetchingContracts,
    navigation: createNavigation(contracts),
  }
}

const createNavigation = (contracts: Option<Contract[]>): Option<Navigation> =>
  pipe(
    contracts,
    map((contracts) => {
      if (contracts.length === 1) {
        const [contract] = contracts
        return { mode: 'single', contractId: contract.id }
      }

      return { mode: 'multiple' }
    }),
  )

export { useBottomNav }

import { fadeIn, trackEvent } from 'lib'
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded'
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'

import * as S from './Header.styled'
type Props = {
  title: string
  backButtonRoute?: string
}
const Header = ({ title, backButtonRoute }: Props) => {
  return (
    <S.Header variants={fadeIn({ duration: 0.15 })}>
      <S.Leading>
        {backButtonRoute && (
          <Link to={backButtonRoute}>
            <Box pr={2}>
              <S.LogoWrapper>
                <KeyboardBackspaceRoundedIcon />
              </S.LogoWrapper>
            </Box>
          </Link>
        )}
        <S.Title>{title}</S.Title>
      </S.Leading>
      <a
        href={process.env.REACT_APP_BOT_WHATSAPP_LINK || ''}
        onClick={() => trackEvent('Clique no Link Para o WhatsApp')}
      >
        <S.LogoWrapper>
          <HelpOutlineRoundedIcon />
        </S.LogoWrapper>
      </a>
    </S.Header>
  )
}

export { Header }

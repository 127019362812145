import styled from 'styled-components'

const Wrapper = styled.span`
  text-transform: capitalize;
`

type Props = {
  children: string
  className?: string
}

const Capitalize = ({ children, ...props }: Props) => (
  <Wrapper {...props}>{children.toLowerCase()}</Wrapper>
)

export { Capitalize }

import { get } from 'client'
import { useQuery } from 'react-query'
import { DynamicSimulationDataResponse } from 'types'

import { normalizeDynamicSimulationResponse } from 'client/normalizations'

export const useSimulation = (contractId: string) => {
  const {
    data: simulationData,
    isFetching,
    isError,
    isLoading,
    refetch,
    isRefetchError,
  } = useQuery({
    queryKey: 'simulations',
    queryFn: () =>
      get<DynamicSimulationDataResponse>(`/simulations/${contractId}`),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false,
  })
  return {
    isLoading,
    isFetching,
    isError,
    isRefetchError,
    dynamicSimulation: normalizeDynamicSimulationResponse(simulationData),
    refetch,
  }
}

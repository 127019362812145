import axios from 'axios'
import { pipe } from 'fp-ts/lib/function'
import { getOrElse } from 'fp-ts/lib/Option'
import { getTokenFromStorage } from 'lib'

const { REACT_APP_API_URL: API_URL } = process.env

export async function get<T>(path: string) {
  const token = getTokenWithDefault()
  const { data } = await axios.get<T>(`${API_URL}${path}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
  return data
}

export async function post<T, E>(path: string, body: T) {
  const token = getTokenWithDefault()
  const data = await axios.post<T, E>(`${API_URL}${path}`, body, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
  return data
}

export async function put<T, E>(path: string, body: T) {
  const token = getTokenWithDefault()
  const data = await axios.put<T, E>(`${API_URL}${path}`, body, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
  return data
}

const getTokenWithDefault = () =>
  pipe(
    getTokenFromStorage(),
    getOrElse(() => ''),
  )

import Loader from 'react-content-loader'
import { theme } from 'lib'

export const ContentLoader = () => (
  <div style={{ width: '100%' }} data-testid='documents-content-loader'>
    <Loader
      speed={2}
      width={100}
      height={500}
      backgroundColor={theme.colors.loaderBackground}
      foregroundColor={theme.colors.loaderForeground}
      style={{ width: '100%', height: '500px' }}
    >
      <rect
        x='0'
        y='0'
        rx={theme.radius.small}
        ry={theme.radius.large}
        width='100%'
        height='15.0rem'
      />
      <rect
        x='0'
        y='174'
        rx={theme.radius.small}
        ry={theme.radius.large}
        width='100%'
        height='15.0rem'
      />

      <rect
        x='0'
        y='348'
        rx={theme.radius.small}
        ry={theme.radius.large}
        width='100%'
        height='15.0rem'
      />
    </Loader>
  </div>
)

import React from 'react'
import ReactDOM from 'react-dom'
import mixpanel from 'mixpanel-browser'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from './App'

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '')

if (
  process.env.REACT_APP_SENTRY_DSN &&
  process.env.REACT_APP_SENTRY_ENVIRONMENT_NAME
) {
  try {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT_NAME,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.2,
    })
  } catch {}
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)

import { motion } from 'framer-motion'
import { media, theme } from 'lib'
import styled from 'styled-components'

const Overlay = motion(styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: ${theme.zIndex.aboveAll + 1};
  background: ${theme.colors.white};
  padding: ${theme.spacing[4]} ${theme.spacing[3]};
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${media.greaterThan('tablet')`
    max-width: ${theme.constants.wrapperMaxWidth};
    left: 50%;
    margin-left: calc(-${theme.constants.wrapperMaxWidth} / 2 );
  `}
`)

const CloseButton = styled.button`
  border: 0;
  outline: 0;
  background: 0;
  font-size: 0;
  box-shadow: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

export { Overlay, CloseButton }

import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: initial;
`

const Input = styled.input`
  width: 1.8rem;
  height: 1.8rem;
  margin-top: 0.4em;
`

export { Wrapper, Input }

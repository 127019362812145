import styled from 'styled-components'
import { motion } from 'framer-motion'
import { media, theme } from 'lib'
import { BACK_ARROW_ICON } from 'assets'
import { SvgIcon } from '@material-ui/core'

export const Header = styled(motion.header)`
  width: 100%;
  max-width: ${theme.constants.wrapperMaxWidth};
  top: 0;
  background: ${theme.colors.blackBlue};
  height: ${theme.constants.headerHeight};
  border-radius: 0 0 ${theme.radius.large} ${theme.radius.large};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${theme.spacing[3]};
  z-index: ${theme.zIndex.aboveAll};

  ${media.lessThan('tablet')`
    width: 100%;
    left: 0;
  `}

  ${media.greaterThan('tablet')`
    max-width: ${theme.constants.wrapperMaxWidth};
    border-radius: 0 0 ${theme.radius.xLarge} ${theme.radius.xLarge};
  `}
`

export const BackButton = styled.a`
  border: 0;
  background: 0;
  outline: 0;
  box-shadow: 0;
  padding: 0;
  text-decoration: 0;

  &:after {
    content: url(${BACK_ARROW_ICON});
  }
`

export const Leading = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.h1`
  font-size: 1.4rem;
  color: ${theme.colors.white};
  font-weight: 500;
  font-family: ${theme.fontFamily};
  text-align: left;
`

export const LogoWrapper = styled(SvgIcon)`
  text-decoration: none;
  text-tranform: none;
  outline: none;
  color: white;
  width: ${theme.spacing[3]} !important;
  height: ${theme.spacing[3]} !important;
`

import { GREEN_CHECKED_CIRCLE_ICON, SUBTRACT_ICON } from 'assets'

const useSimulationCard = () => {
  const handleLoanStatusIcon = (loanApproval: boolean) =>
    loanApproval ? GREEN_CHECKED_CIRCLE_ICON : SUBTRACT_ICON

  const handleIconLabel = (loanApproval: boolean) =>
    loanApproval ? 'Aprovado' : 'Reprovado'

  return {
    handleLoanStatusIcon,
    handleIconLabel,
  }
}

export { useSimulationCard }

import { pipe } from 'fp-ts/lib/function'
import { fold, fromNullable } from 'fp-ts/lib/Option'
import { trackEvent } from 'lib'
import { Nullable } from 'types'
import * as S from './Button.styled'
import { ButtonProps, LinkMixpanelEvent, LinkProps } from './Button.types'

const { getIconColor } = S

const Button = ({
  block = false,
  variant = 'primary',
  kind = 'default',
  children,
  ...props
}: ButtonProps) => (
  <S.Button {...props} kind={kind} variant={variant} block={block ? 1 : 0}>
    {children}
  </S.Button>
)

const Link = ({
  block = false,
  external = false,
  variant = 'primary',
  kind = 'default',
  suffixIcon,
  children,
  to,
  mixpanelEvent,
}: LinkProps) => {
  const content = (
    <>
      {children}
      {suffixIcon && (
        <S.SuffixIcon
          size='2.0rem'
          color={getIconColor(variant, kind)}
          path={suffixIcon}
        />
      )}
    </>
  )

  if (external) {
    return (
      <S.ExternalLink
        onClick={() => trackLinkEvent(mixpanelEvent)}
        href={to}
        target='_blank'
        variant={variant}
        block={block ? 1 : 0}
        kind={kind}
      >
        {content}
      </S.ExternalLink>
    )
  }

  return (
    <S.Link
      onClick={() => trackLinkEvent(mixpanelEvent)}
      to={to}
      kind={kind}
      variant={variant}
      block={block ? 1 : 0}
    >
      {content}
    </S.Link>
  )
}

const trackLinkEvent = (event: Nullable<LinkMixpanelEvent>) =>
  pipe(
    event,
    fromNullable,
    fold(
      () => {},
      (event) => trackEvent(event.name, event.properties),
    ),
  )

Button.Link = Link

export { Button }

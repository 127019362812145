import SPINNER_CIRCLE from './spinner-circle.svg'

type SpinnerProps = {
  size?: string
}

const Spinner = ({ size = '24' }: SpinnerProps) => (
  <img width={size} height={size} src={SPINNER_CIRCLE} />
)

export { Spinner }

import { createMuiTheme } from '@material-ui/core/styles'
declare module '@material-ui/core/styles/createBreakpoints' {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  /* eslint-disable no-unused-vars */
  interface BreakpointOverrides {
    xxs: true
    xxl: true
  }
}

export const muiTheme = createMuiTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 375,
      sm: 600,
      md: 768,
      lg: 960,
      xl: 1280,
      xxl: 1920,
    },
  },
})

export type SpacingValue = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7

export type Theme = {
  fontFamily: string
  colors: {
    primary: string
    text: string
    white: string
    red: string
    background: string
    shape01: string
    blackGreen: string
    blackBlue: string
    loaderBackground: string
    loaderForeground: string
    gray1: string
    gray2: string
    black: string
    orange: string
  }
  constants: {
    headerHeight: string
    bottomNavHeight: string
    wrapperMaxWidth: string
    contentPadding: string
  }
  zIndex: {
    hidden: number
    base: number
    middle: number
    top: number
    aboveAll: number
  }
  radius: {
    xSmall: string
    small: string
    medium: string
    large: string
    xLarge: string
  }
  spacing: {
    [Key in SpacingValue]: string
  }
}

export const theme: Theme = {
  fontFamily: "'Poppins', Helvetica, sans-serif",
  colors: {
    primary: '#353FDF',
    red: '#FF6584',
    text: '#777790',
    background: '#F6F9FC',
    shape01: '#EEF3F9',
    white: '#fff',
    blackGreen: '#41B9AA',
    blackBlue: '#2B35CE',
    loaderBackground: 'rgba(202, 213, 230, .2)',
    loaderForeground: 'rgba(179, 190, 204, .2)',
    gray1: '#D9DEED',
    gray2: '#777790',
    black: '#1E1B3E',
    orange: '#FEA945',
  },
  constants: {
    headerHeight: '4.8rem',
    bottomNavHeight: '5.2rem',
    wrapperMaxWidth: '72.0rem',
    contentPadding: '4rem',
  },
  zIndex: {
    hidden: -1,
    base: 10,
    middle: 20,
    top: 30,
    aboveAll: 40,
  },
  radius: {
    xSmall: '.4rem',
    small: '1.2rem',
    medium: '1.6rem',
    large: '2.4rem',
    xLarge: '3.2rem',
  },
  spacing: {
    0: '0',
    1: '.8rem',
    2: '1.6rem',
    3: '2.4rem',
    4: '3.2rem',
    5: '4.0rem',
    6: '4.8rem',
    7: '5.6rem',
  },
}

import { HTMLMotionProps } from 'framer-motion'
import { ReactNode } from 'react'

import * as S from './Card.styled'
import { StyledProps } from './Card.types'

type Props = StyledProps &
  HTMLMotionProps<'div'> & {
    children: ReactNode
    testId?: string
  }

const Card = ({
  children,
  minWidth,
  testId,
  initial,
  variants,
  animate,
  className,
}: Props) => (
  <S.Card
    layout
    className={className}
    variants={variants}
    initial={initial}
    animate={animate}
    minWidth={minWidth}
    data-testid={testId}
  >
    {children}
  </S.Card>
)

export { Card }

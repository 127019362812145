import { media, theme } from 'lib'
import styled from 'styled-components'

const StyledCopyright = styled.p`
  display: none;

  ${media.greaterThan('tablet')`
    display: block;
    position: fixed;
    font-size: 1.6rem;
    font-family: ${theme.fontFamily};
    color: ${theme.colors.black};
    bottom: 0rem;
    font-weight: 500;
    letter-spacing: -0.05em;
    left: 50%;
    transform: translateX(-50%);
    z-index: ${theme.zIndex.top};
  `}
`

const Copyright = () => <StyledCopyright>Soma+ © 2021 </StyledCopyright>

export { Copyright }

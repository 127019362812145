import Loader from 'react-content-loader'
import { theme } from 'lib'
import Box from '@material-ui/core/Box'

export const Texts = () => (
  <Loader
    speed={2}
    width={100}
    height={96}
    backgroundColor={theme.colors.loaderBackground}
    foregroundColor={theme.colors.loaderForeground}
    style={{ width: '100%', height: '86px' }}
    data-testid='texts-loader'
  >
    <rect
      x='0'
      y='0'
      rx={theme.radius.small}
      ry={theme.radius.small}
      width='70%'
      height='3.5rem'
    />
    <rect
      x='0'
      y='46'
      rx={theme.radius.small}
      ry={theme.radius.large}
      width='100%'
      height='5.0rem'
    />
  </Loader>
)

export const Card = () => (
  <Loader
    speed={2}
    width={100}
    height={250}
    backgroundColor={theme.colors.loaderBackground}
    foregroundColor={theme.colors.loaderForeground}
    style={{ width: '100%', height: '250' }}
    data-testid='send-documents-loader'
  >
    <rect
      x='0'
      y='0'
      rx={theme.radius.small}
      ry={theme.radius.small}
      width='100%'
      height='25.0rem'
    />
  </Loader>
)

export const ContractsSection = () => (
  <Box mt={7}>
    <Loader
      speed={2}
      width={100}
      height={636}
      backgroundColor={theme.colors.loaderBackground}
      foregroundColor={theme.colors.loaderForeground}
      style={{ width: '100%', height: '636' }}
      data-testid='contracts-section-loader'
    >
      <rect
        x='0'
        y='0'
        rx={theme.radius.small}
        ry={theme.radius.small}
        width='70%'
        height='3.5rem'
      />
      <rect
        x='0'
        y='46'
        rx={theme.radius.small}
        ry={theme.radius.large}
        width='100%'
        height='5.0rem'
      />
      <rect
        x='0'
        y='112'
        rx={theme.radius.small}
        ry={theme.radius.large}
        width='100%'
        height='25.0rem'
      />
      <rect
        x='0'
        y='378'
        rx={theme.radius.small}
        ry={theme.radius.large}
        width='100%'
        height='25.0rem'
      />
    </Loader>
  </Box>
)

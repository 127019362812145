import styled from 'styled-components'
import { theme } from 'lib'
import { Layout } from 'ui'

export const Wrapper = styled(Layout)`
  max-width: ${theme.constants.wrapperMaxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

import { pipe } from 'fp-ts/lib/function'
import { fold, Option, isSome } from 'fp-ts/lib/Option'
import { theme } from 'lib'
import { Document, DocumentType } from 'types'
import { Badge, Typography, Button } from 'ui'
import Box from '@material-ui/core/Box'
import { PDF_DOWNLOAD_ICON } from 'assets'
import { colorByStatus, labelByStatus, labelByType } from '../documents-content'
import * as S from './DocumentPreview.styled'

type Props = {
  document: Option<Document>
}

const DocumentPreview = ({ document: maybeDocument }: Props) => {
  if (isSome(maybeDocument)) {
    const document = maybeDocument.value

    return (
      <Box
        flexGrow={3}
        display='flex'
        flexDirection='column'
        data-testid='document-preview'
      >
        <Box display='flex' alignContent='center' alignItems='center'>
          <Box mr={2}>
            <Typography variant='h2'>
              {getDocumentName(document.type)}
            </Typography>
          </Box>
          <Badge color={colorByStatus[document.status]}>
            {labelByStatus[document.status]}
          </Badge>
        </Box>

        {isSome(document.reason) && (
          <Box my={1}>
            <Typography color={theme.colors.gray2}>
              {document.reason.value}
            </Typography>
          </Box>
        )}

        {['png', 'jpg', 'jpeg'].includes(document.fileType) && (
          <Box my={4}>
            <S.Image src={document.remotePath} />
          </Box>
        )}

        {document.fileType === 'pdf' && (
          <Box
            display='flex'
            justifyContent='center'
            flexDirection='column'
            alignItems='center'
            flexGrow={3}
          >
            <img src={PDF_DOWNLOAD_ICON} />

            <Box mt={1} mb={3} px={2}>
              <S.DownloadText align='center'>
                Você precisa realizar o download do arquivo para visualizá-lo.
              </S.DownloadText>
            </Box>

            <Button.Link external to={document.remotePath}>
              Baixar arquivo
            </Button.Link>
          </Box>
        )}
      </Box>
    )
  }

  return null
}

const getDocumentName = (type: Option<DocumentType>) =>
  pipe(
    type,
    fold(
      () => 'Documento',
      (documentType) => labelByType[documentType],
    ),
  )

export { DocumentPreview }

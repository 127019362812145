import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'

import Box from '@material-ui/core/Box'
import { theme } from 'lib'
import { ReactNode } from 'react'
import { AnimatePresence } from 'framer-motion'
import * as S from './ModalWindow.styled'

type Props = {
  visible: boolean
  onClose: () => void
  children: ReactNode
  testId?: string
}

const ModalWindow = ({ visible, onClose, children, testId }: Props) => (
  <AnimatePresence>
    {visible && (
      <S.Overlay
        data-testid={testId}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
      >
        <Box display='flex' justifyContent='flex-end'>
          <S.CloseButton onClick={onClose}>
            <Icon path={mdiClose} color={theme.colors.black} size='2.4rem' />
          </S.CloseButton>
        </Box>
        {children}
      </S.Overlay>
    )}
  </AnimatePresence>
)

export { ModalWindow }

import { pipe } from 'fp-ts/lib/function'
import { fold, isSome } from 'fp-ts/lib/Option'
import { fadeIn, trackEvent } from 'lib'
import { Document } from 'types'
import { Typography } from 'ui'
import Box from '@material-ui/core/Box'
import { DocumentCard } from '../DocumentCard'
import { colorByStatus, labelByStatus, labelByType } from '../documents-content'
import * as S from './SentDocuments.styled'

type Props = {
  documents: Document[]
  onDocumentClick: (doc: Document) => void
}

const SentDocuments = ({ onDocumentClick, documents }: Props) => (
  <Box data-testid='sent-documents-list'>
    <Typography
      animate='visible'
      initial='hidden'
      variants={fadeIn({ delay: 0.8 })}
      variant='h3'
    >
      Você já enviou
    </Typography>
    <S.DocumentsList>
      {documents.map((document, id) => (
        <S.DocumentItem
          onClick={() => {
            onDocumentClick(document)
            trackPreviewClick(document)
          }}
          animate='visible'
          initial='hidden'
          variants={fadeIn({ delay: 0.8 + id * 0.2 })}
          key={id}
        >
          <DocumentCard
            clickable={true}
            name={pipe(
              document.type,
              fold(
                () => `Documento ${id + 1}`,
                (docType) => labelByType[docType],
              ),
            )}
            url={document.remotePath}
            fileType={document.fileType}
          >
            <S.StatusBadge color={colorByStatus[document.status]}>
              {labelByStatus[document.status]}
            </S.StatusBadge>
          </DocumentCard>
        </S.DocumentItem>
      ))}
    </S.DocumentsList>
  </Box>
)
const trackPreviewClick = (document: Document) => {
  trackEvent('Clique no Preview do Documento', {
    Categoria: document.category,
    'Tipo do Arquivo': isSome(document.type)
      ? labelByType[document.type.value]
      : '',
    'Status do Arquivo': labelByStatus[document.status],
  })
}

export { SentDocuments }

/* eslint-disable no-console */
import { get } from 'client'
import { useQuery } from 'react-query'

import { Nullable, SimulationParamsResponse } from 'types'
import { fromNullable, map } from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { normalizeSimulationParams } from 'client/normalizations'

const useSimulationParams = (contractId: string) => {
  const {
    data: simulationParamsData,
    isFetching,
    isError,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['simulationParams', contractId],
    queryFn: () =>
      get<SimulationParamsResponse>(`/simulation_params/${contractId}`),
    retry: false,
    refetchOnWindowFocus: false,
  })

  return {
    isLoading,
    isFetching,
    isError,
    simulationParams: normalizeSimulationParamsResponse(simulationParamsData),
    refetch,
  }
}

const normalizeSimulationParamsResponse = (
  result: Nullable<SimulationParamsResponse>,
) =>
  pipe(
    result,
    fromNullable,
    map((result) => result.data),
    map((data) => normalizeSimulationParams(data)),
  )

export { useSimulationParams }

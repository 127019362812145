import { fromNullable, isNone, isSome } from 'fp-ts/lib/Option'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { EXPIRED_SESSION, isPublicRoute } from 'routes'
import { queryClientEmitter, UNAUTHORIZED_ERROR } from 'client'
import { trackEvent, useIdentifyUser } from 'lib'

const STORAGE_KEY = '_soma_token'
const URL_PARAM = 'token'

const SessionManager = () => {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const { identify } = useIdentifyUser()

  useEffect(() => {
    const maybeToken = getTokenFromSearch(search)
    const tokenStorage = getTokenFromStorage()

    if (isSome(maybeToken)) {
      storeToken(maybeToken.value)
    }

    if (
      isNone(maybeToken) &&
      isNone(tokenStorage) &&
      !isPublicRoute(pathname)
    ) {
      history.push(EXPIRED_SESSION)
    } else {
      identify()
      trackEvent('Acessou Wallet')
    }
  }, [])

  useEffect(() => {
    const handleRedirect = () =>
      !isPublicRoute(pathname) && history.push(EXPIRED_SESSION)
    queryClientEmitter.on(UNAUTHORIZED_ERROR, handleRedirect)

    return () => queryClientEmitter.off(UNAUTHORIZED_ERROR, handleRedirect)
  }, [])

  return null
}

const storeToken = (token: string) => {
  return window.localStorage.setItem(STORAGE_KEY, token)
}

const getTokenFromSearch = (search: string) =>
  fromNullable(new URLSearchParams(search).get(URL_PARAM))

const getTokenFromStorage = () =>
  fromNullable(window.localStorage.getItem(STORAGE_KEY))

export { SessionManager, getTokenFromStorage }

/* eslint-disable multiline-ternary */
import { isSome } from 'fp-ts/lib/Option'
import { fadeIn, theme } from 'lib'
import { RestrictionType } from 'types'
import { HelpCenter, Typography } from 'ui'

import { useSetPreviousRoute } from 'lib/navigation'
import Box from '@material-ui/core/Box'
import { PreAnalysisRestriction } from './types'
import { RestrictionsList } from './RestrictionsList'
import { usePreAnalysis } from './usePreAnalysis'
import { ContentLoader } from './ContentLoader'

const { REACT_APP_WHATSAPP_CHAT_LINK: CHAT_LINK = '' } = process.env

const PreAnalysis = () => {
  useSetPreviousRoute('/')
  const { restrictions, isFetching } = usePreAnalysis()

  if (isFetching) {
    return <ContentLoader />
  }

  if (isSome(restrictions)) {
    return (
      <>
        <Box mb={7}>
          <Typography
            animate='visible'
            initial='hidden'
            variants={fadeIn({ delay: 0 })}
            variant='h2'
          >
            Pré-Análise
          </Typography>
          {restrictions.value.length > 0 ? (
            <Typography
              color={theme.colors.gray2}
              animate='visible'
              initial='hidden'
              variants={fadeIn({ delay: 0.15 })}
            >
              Encontramos algumas pendências durante a pré-análise, mas não se
              preocupe, a <Typography.Highlight>Soma+</Typography.Highlight> irá
              te ajudar a resolver!
            </Typography>
          ) : (
            <Typography
              color={theme.colors.gray2}
              animate='visible'
              initial='hidden'
              variants={fadeIn({ delay: 0.15 })}
            >
              Por aqui você consegue visualizar as pendências que encontramos
              durante a consulta do seu CPF. Se não aparecer nenhuma, você pode
              avançar para as próximas etapas (simulações e documentação)!
            </Typography>
          )}
        </Box>

        <RestrictionsList
          testId='pending-payments-list'
          animationDelay={0.35}
          title='Pagamentos pendentes'
          restrictions={restrictions.value.filter(byType('PENDING_PAYMENT'))}
          disclaimer='É necessário quitar seus pagamentos pendentes para dar continuidade no financiamento.'
        />

        <RestrictionsList
          testId='protest-list'
          animationDelay={0.45}
          title='Protestos'
          restrictions={restrictions.value.filter(byType('PROTEST'))}
          disclaimer='É necessário dar baixa nos seus protestos para dar continuidade no seu financiamento.'
        />

        <RestrictionsList
          testId='overdue-debts-list'
          animationDelay={0.55}
          title='Dívidas vencidas'
          restrictions={restrictions.value.filter(byType('OVERDUE_DEBT'))}
          disclaimer='É necessário regularizar suas dívidas vencidas para dar continuidade no financiamento.'
        />

        <RestrictionsList
          testId='check-list'
          animationDelay={0.65}
          title='Cheques'
          restrictions={restrictions.value.filter(byType('CHECK'))}
          disclaimer='É necessário regularizar os seus cheques para dar continuidade no financiamento.'
        />
        <Box mb={5}>
          <HelpCenter
            title={
              <>
                Ficou alguma dúvida? Fale com a
                <Typography.Highlight> Soma+</Typography.Highlight>
              </>
            }
            action='Falar com a Soma+'
            link={CHAT_LINK}
          />
        </Box>
      </>
    )
  }
  return null
}

const byType = (restrictionType: RestrictionType) => (
  restriction: PreAnalysisRestriction,
) => restriction.type === restrictionType

export default PreAnalysis

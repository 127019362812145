import { CONTRACT, DOCUMENTS, PRE_ANALYSIS, SIMULATIONS } from 'routes'

import { ModalWindow } from 'ui/ModalWindow'
import { isSome } from 'fp-ts/lib/Option'
import { useLocation } from 'react-router-dom'
import {
  BAR_CHART_ICON,
  CONTACT_PAGE_ICON,
  HOME_ICON,
  MANAGE_SEARCH_ICON,
} from 'assets'
import { ContractsModal } from './ContractsModal'
import * as S from './BottomNav.styled'
import { useBottomNav } from './useBottomNav'
import { BottomNavLink } from './BottomNavLink'

type Props = {
  testId?: string
}

const BottomNav = ({ testId }: Props) => {
  const {
    closeModal,
    openModal,
    modal,
    isFetchingContracts,
    navigation,
  } = useBottomNav()
  const { pathname } = useLocation()
  if (!isFetchingContracts && isSome(navigation)) {
    return (
      <>
        <S.Wrapper {...navAnimation} data-testid={testId}>
          <BottomNavLink
            to='/'
            description='home'
            icon={HOME_ICON}
            active={pathname === '/'}
          />
          {navigation.value.mode === 'single' && (
            <>
              <BottomNavLink
                to={`${CONTRACT.replace(
                  ':id',
                  navigation.value.contractId,
                )}${PRE_ANALYSIS}`}
                description='pré-análise'
                icon={MANAGE_SEARCH_ICON}
                active={pathname.includes(PRE_ANALYSIS)}
              />
              <BottomNavLink
                to={`${CONTRACT.replace(
                  ':id',
                  navigation.value.contractId,
                )}${SIMULATIONS}`}
                description='simulações'
                icon={BAR_CHART_ICON}
                active={pathname.includes(SIMULATIONS)}
              />
            </>
          )}

          {navigation.value.mode === 'multiple' && (
            <>
              <S.Button
                active={pathname.includes(PRE_ANALYSIS) ? 'true' : 'false'}
                onClick={() => {
                  openModal('analysis')
                }}
              >
                <img src={MANAGE_SEARCH_ICON} />
                pré-análise
              </S.Button>
              <S.Button
                active={pathname.includes(SIMULATIONS) ? 'true' : 'false'}
                onClick={() => openModal('simulations')}
              >
                <img src={BAR_CHART_ICON} />
                simulações
              </S.Button>
            </>
          )}
          <BottomNavLink
            to={DOCUMENTS}
            description='docs'
            icon={CONTACT_PAGE_ICON}
            active={pathname.includes(DOCUMENTS)}
          />
          <ModalWindow
            visible={modal.visible}
            onClose={closeModal}
            testId='contracts-modal'
          >
            <ContractsModal route={modal.route} onClick={closeModal} />
          </ModalWindow>
        </S.Wrapper>
      </>
    )
  }

  return <div />
}

const navAnimation = {
  animate: 'visible',
  initial: 'hidden',
  variants: {
    hidden: {
      y: 20,
    },
    visible: {
      transition: { duration: 0.15 },
      y: 0,
    },
  },
}

export { BottomNav }

import { DOCUMENTS_ERROR_ICON } from 'assets'
import { theme } from 'lib'
import { Button } from 'ui/Button'
import { Dialog } from 'ui/Dialog'
import { Typography } from 'ui/Typography'

import Box from '@material-ui/core/Box'
import * as S from './ErrorDialog.styled'

type Props = {
  visible: boolean
  onClose?: () => void
  onConfirm: () => void
  title?: string
  description?: string
  confirmText?: string
}

const ErrorDialog = ({
  visible,
  onClose,
  onConfirm,
  title = 'Algo saiu errado',
  description = 'Não foi possível exibir o conteúdo.',
  confirmText = 'Entendi',
}: Props) => (
  <Dialog visible={visible} onClose={onClose}>
    <Box display='flex' flexDirection='column'>
      <S.Image src={DOCUMENTS_ERROR_ICON} />
      <Box my={2} display='flex' alignItems='center' justifyContent='center'>
        <S.Title align='center' color={theme.colors.red}>
          {title}
        </S.Title>
      </Box>
      <Typography align='center' color={theme.colors.gray2}>
        {description}
      </Typography>
      <Box mt={2} justifyContent='center' display='flex'>
        <Button
          kind='link'
          data-testid='dialog-confirm-button'
          onClick={onConfirm}
        >
          {confirmText}
        </Button>
      </Box>
    </Box>
  </Dialog>
)

export { ErrorDialog }

/* eslint-disable no-console */
import { fold, fromNullable } from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

export const decimalFromInt = (value: number | null | undefined) =>
  pipe(
    value,
    fromNullable,
    fold(
      () => '0,00',
      (value) =>
        new Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 2,
        }).format(parseFloat(value + '') / 100),
    ),
  )

export const intFromString = (value: string | null | undefined): number =>
  pipe(
    value,
    fromNullable,
    fold(
      () => 0,
      (value) => parseInt(value.replace(/[.,\D]/g, '')),
    ),
  )

export const yearsFromMonths = (value: number | null | undefined) =>
  pipe(
    value,
    fromNullable,
    fold(
      () => 0,
      (value) => value / 12,
    ),
  )

export const monthsFromYears = (value: number | null | undefined) =>
  pipe(
    value,
    fromNullable,
    fold(
      () => 0,
      (value) => value * 12,
    ),
  )

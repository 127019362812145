import { get } from 'client'
import { useQuery } from 'react-query'
import { ProponentDataResponse } from 'types'
import { normalizeProponentResponse } from 'client/normalizations'

type HookConfig = {
  enabled?: boolean
}

export const useMe = (config?: HookConfig) => {
  const { data: proponentData, isFetching, isError, refetch } = useQuery({
    queryKey: ['proponents', 'me'],
    queryFn: () => get<ProponentDataResponse>('/proponents/me'),
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  })

  return {
    refetch,
    isFetching,
    isError,
    proponent: normalizeProponentResponse(proponentData),
  }
}

import { ReactNode } from 'react'
import Box from '@material-ui/core/Box'

import * as S from './Disclaimer.styled'
import HELP_ICON from './help-icon.svg'
import ALERT_ICON from './alert-icon.svg'

type Props = {
  message: ReactNode
  icon?: 'help' | 'alert'
}

const Disclaimer = ({ message, icon = 'help' }: Props) => (
  <S.Wrapper>
    <Box mr={2}>
      <img src={icon === 'help' ? HELP_ICON : ALERT_ICON} />
    </Box>
    <S.Text>{message}</S.Text>
  </S.Wrapper>
)

export { Disclaimer }

import Icon from '@mdi/react'
import { mdiChevronDown } from '@mdi/js'
import { ReactNode, useState } from 'react'

import { theme } from 'lib'

import { AnimatePresence, motion } from 'framer-motion'
import * as S from './Accordion.styled'

type Props = {
  children: ReactNode
  title: string
  open?: boolean
}

const Accordion = ({ children, title, open = false }: Props) => {
  const [isOpen, setIsOpen] = useState(open)

  return (
    <S.Wrapper>
      <S.Header
        role='button'
        data-testid='collapse-button'
        onClick={() => setIsOpen(!isOpen)}
      >
        <S.Title> {title}</S.Title>
        <S.ArrowButton isOpen={isOpen}>
          <Icon
            path={mdiChevronDown}
            color={theme.colors.primary}
            size='2.6rem'
          />
        </S.ArrowButton>
      </S.Header>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </S.Wrapper>
  )
}

export { Accordion }

import { queryClientEmitter, REQUEST_ERROR } from 'client'
import { none as None, some as Some, Option, isSome } from 'fp-ts/lib/Option'
import { ReactNode, useEffect, useState } from 'react'
import { ErrorBoundary as Boundary } from 'react-error-boundary'
import { useLocation } from 'react-router-dom'

import { ErrorDialog } from '../ErrorDialog'

type Props = {
  children: ReactNode
}

type HandleErrorProps = {
  error: unknown
}

const HandleError = ({ error }: HandleErrorProps) => {
  throw error
}

const ErrorBoundary = ({ children }: Props) => {
  const location = useLocation()
  const [error, setError] = useState<Option<unknown>>(None)

  useEffect(() => {
    const handleError = (error: unknown) => setError(Some(error))
    queryClientEmitter.on(REQUEST_ERROR, handleError)

    return queryClientEmitter.off(REQUEST_ERROR, handleError)
  }, [])

  useEffect(() => {
    setError(None)
  }, [location.pathname])

  return (
    <Boundary
      onReset={() => setError(None)}
      resetKeys={[error]}
      FallbackComponent={({ resetErrorBoundary }) => (
        <ErrorDialog
          visible={true}
          onConfirm={resetErrorBoundary}
          confirmText='Tentar novamente'
        />
      )}
    >
      {isSome(error) ? <HandleError error={error.value} /> : null}
      {children}
    </Boundary>
  )
}

export { ErrorBoundary }

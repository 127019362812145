import { Card, Button, Divider, LabeledValue } from 'ui'
import { useState } from 'react'
import {
  BB_LOGO,
  BRADESCO_LOGO,
  CAIXA_LOGO,
  INTER_LOGO,
  ITAU_LOGO,
  OPPORTUNITY_LOGO,
  SANTANDER_LOGO,
} from 'assets'
import { AnimatePresence, motion } from 'framer-motion'
import { Box } from '@material-ui/core'
import { theme } from 'lib'
import { BankName } from 'types'
import * as S from './SimulationCard.styled'
import { useSimulationCard } from './useSimulationCard'

type Props = {
  bank: BankName
  cet: string
  installment: string
  lastInstallment: string
  installments: number
  interestRate: string
  amortizationType: string
  loanAmount: string
  loanApproval: boolean
  sumInstallments: string
  amortization: string
  notaryFee: string
  interestRateType: string
}

const SimulationCard = ({
  bank,
  cet,
  installment,
  lastInstallment,
  installments,
  interestRate,
  amortizationType,
  loanAmount,
  loanApproval,
  sumInstallments,
  amortization,
  notaryFee,
  interestRateType,
}: Props) => {
  const [expand, setExpand] = useState(false)

  const { handleLoanStatusIcon, handleIconLabel } = useSimulationCard()

  return (
    <S.Wrapper>
      <Card minWidth='25.0rem'>
        <motion.div layout>
          <S.BankLogo src={logos[bank]} alt={bank} />
          <LabeledValue
            labelSize='small'
            captionSize='medium'
            labelColor={theme.colors.gray2}
            label='CET'
          >
            {cet}% a.a
          </LabeledValue>
          <LabeledValue
            labelSize='small'
            captionSize='medium'
            labelColor={theme.colors.gray2}
            label='1ª Parcela'
          >
            R${installment}
          </LabeledValue>
          <LabeledValue
            labelSize='small'
            captionSize='medium'
            labelColor={theme.colors.gray2}
            label='Última Parcela'
          >
            R${lastInstallment}
          </LabeledValue>
          <LabeledValue
            labelSize='small'
            captionSize='medium'
            labelColor={theme.colors.gray2}
            label='Prazo'
          >
            {getInstallmentsText(installments)} / {installments} parcelas
          </LabeledValue>
          <S.Row>
            <Box mr={7}>
              <LabeledValue
                labelSize='small'
                captionSize='medium'
                labelColor={theme.colors.gray2}
                label='Tabela'
              >
                {amortizationType}
              </LabeledValue>
            </Box>
            <LabeledValue
              labelSize='small'
              captionSize='medium'
              labelColor={theme.colors.gray2}
              label='Juros a.a.'
            >
              {interestRate}%
            </LabeledValue>
          </S.Row>
          <LabeledValue
            labelSize='small'
            captionSize='medium'
            labelColor={theme.colors.gray2}
            label='Valor Total Pré-aprovado'
          >
            R${loanAmount}
            <S.CheckIcon
              src={handleLoanStatusIcon(loanApproval)}
              alt={handleIconLabel(loanApproval)}
            />
          </LabeledValue>
          <Box py={1}>
            <Divider />
          </Box>
        </motion.div>

        <AnimatePresence>
          {expand && (
            <motion.div
              layout
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <LabeledValue
                labelSize='small'
                captionSize='medium'
                labelColor={theme.colors.gray2}
                label={'Somatório' + '\n' + 'parcelas + juros'}
              >
                R${sumInstallments}
              </LabeledValue>
              <LabeledValue
                labelSize='small'
                captionSize='medium'
                labelColor={theme.colors.gray2}
                label={'Amortização'}
              >
                R${amortization}
              </LabeledValue>
              {notaryFee !== '0,00' && (
                <LabeledValue
                  labelSize='small'
                  captionSize='medium'
                  labelColor={theme.colors.gray2}
                  label='Taxas cartoriais (inclusas)'
                >
                  R${notaryFee}
                </LabeledValue>
              )}
              <LabeledValue
                labelSize='small'
                captionSize='medium'
                labelColor={theme.colors.gray2}
                label='Taxa de Juros'
              >
                {interestRateType}
              </LabeledValue>
              <Divider />
            </motion.div>
          )}
        </AnimatePresence>
        <S.ButtonWrapper layout>
          <Button
            onClick={() => (expand ? setExpand(false) : setExpand(true))}
            kind='link'
          >
            {expand ? 'Menos detalhes' : 'Mais detalhes'}
          </Button>
        </S.ButtonWrapper>
      </Card>
    </S.Wrapper>
  )
}

function getInstallmentsText(installments: number) {
  const months = installments % 12
  const years = Math.floor(installments / 12)
  if (years === 0) return `${months} ${months === 1 ? 'mês' : 'meses'}`
  if (months === 0) return `${years} ${years === 1 ? 'ano' : 'anos'}`
  return `${years} ${years === 1 ? 'ano' : 'anos'} e ${months} ${
    months === 1 ? 'mês' : 'meses'
  }`
}
const logos = {
  Santander: SANTANDER_LOGO,
  Itaú: ITAU_LOGO,
  Bradesco: BRADESCO_LOGO,
  Opportunity: OPPORTUNITY_LOGO,
  Inter: INTER_LOGO,
  'Banco do Brasil': BB_LOGO,
  'Caixa Econômica Federal': CAIXA_LOGO,
}

export default SimulationCard

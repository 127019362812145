import { useParams } from 'react-router-dom'

import { ModalWindow, Typography, Button, Disclaimer } from 'ui'
import { isSome } from 'fp-ts/lib/Option'
import { fadeIn, theme } from 'lib'
import { useContract, useMe, useSimulation } from 'client'
import { useState, createRef, useEffect } from 'react'

import { useSetPreviousRoute } from 'lib/navigation'
import { CONTRACT } from 'routes'
import Box from '@material-ui/core/Box'
import { AnimateSharedLayout, AnimatePresence, motion } from 'framer-motion'
import { ChangeParameters } from './ChangeParameters'

import * as S from './Simulations.styled'
import ContentLoader from './ContentLoader'
import SimulationCard from './SimulationCard/SimulationCard'

type Props = {
  contractId: string
}

const Simulations = ({ contractId }: Props) => {
  const { id } = useParams<{ id: string }>()
  useSetPreviousRoute(CONTRACT.replace(':id', id))
  const { isFetching: isFetchingMe, proponent } = useMe()
  const { isFetching: isFetchingContract, contract } = useContract(contractId)
  const {
    isFetching: isFetchingSimulation,
    dynamicSimulation,
    refetch,
    isError,
    isRefetchError,
    isLoading,
  } = useSimulation(contractId)
  const [isOpen, setIsOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const scrollRef = createRef<HTMLDivElement>()
  const isFetching = isFetchingContract && isFetchingMe && isFetchingSimulation
  const isRefetching = isFetchingSimulation && !isLoading
  const simulationError = isError || isRefetchError
  const closeModal = () => setModalOpen(false)
  useEffect(() => {
    if (!modalOpen) refetch()
  }, [contractId, modalOpen])
  return (
    <>
      {(isFetching || isRefetching) && <ContentLoader />}

      {!isFetching &&
        !isRefetching &&
        !simulationError &&
        isSome(proponent) &&
        isSome(contract) &&
        isSome(dynamicSimulation) && (
          <>
            <AnimateSharedLayout>
              <div ref={scrollRef}>
                <Box>
                  <Typography
                    layout
                    animate='visible'
                    initial='hidden'
                    variants={fadeIn({ delay: 0 })}
                    color={theme.colors.text}
                  >
                    Valor a ser financiado
                  </Typography>
                </Box>
              </div>
              <S.Row>
                <Typography
                  layout
                  animate='visible'
                  initial='hidden'
                  variants={fadeIn({ delay: 0 })}
                  variant='h1'
                >
                  R${dynamicSimulation.value.totalPreApprovedValue}
                </Typography>
                <Button kind='link' onClick={() => setModalOpen(true)}>
                  <Typography highlight color={theme.colors.primary}>
                    Ajustar
                  </Typography>
                </Button>
              </S.Row>
              <Box mb={2}>
                <S.ButtonWrapper
                  layout
                  animate='visible'
                  initial='hidden'
                  variants={fadeIn({ delay: 0 })}
                >
                  <S.DropdownButton
                    kind='link'
                    onClick={() => {
                      !isOpen &&
                        scrollRef.current &&
                        scrollRef.current.scrollIntoView({
                          behavior: 'smooth',
                        })
                      isOpen ? setIsOpen(false) : setIsOpen(true)
                    }}
                  >
                    {isOpen ? 'Ocultar' : 'Ver'} valores discriminados
                  </S.DropdownButton>
                </S.ButtonWrapper>
              </Box>
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    initial='collapsed'
                    animate='open'
                    exit='collapsed'
                    variants={{
                      open: { opacity: 1, height: 'auto' },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 0.4,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <Box mb={3}>
                      <Typography color={theme.colors.text}>
                        Valor do Imóvel a ser pago
                      </Typography>
                      <Typography variant='h3'>
                        R${dynamicSimulation.value.propertyValueToBePaid}
                      </Typography>
                    </Box>
                    <Box mb={3}>
                      <Typography color={theme.colors.text}>
                        Amortização
                      </Typography>
                      <Typography variant='h3'>
                        (-) R${dynamicSimulation.value.amortization}
                      </Typography>
                    </Box>
                    {dynamicSimulation.value.notorialCosts !== '0,00' && (
                      <Box mb={3}>
                        <Typography color={theme.colors.text}>
                          Taxas cartoriais (inclusas)
                        </Typography>
                        <Typography variant='h3'>
                          (+) R${dynamicSimulation.value.notorialCosts}
                        </Typography>
                      </Box>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>

              <motion.div
                layout
                animate='visible'
                initial='hidden'
                variants={fadeIn({ delay: 0.55 })}
              >
                <Box mt={4} mb={2}>
                  <Typography variant='h2'>
                    Melhores simulações para você
                  </Typography>
                </Box>
              </motion.div>

              <S.SimulationsList
                layout
                animate='visible'
                initial='hidden'
                variants={fadeIn({ delay: 0.33 })}
              >
                {dynamicSimulation.value.simulations.map(
                  (simulation, index) => (
                    <SimulationCard
                      key={index}
                      bank={simulation.bankName}
                      cet={simulation.cet}
                      amortizationType={simulation.amortizationType}
                      loanApproval={simulation.desiredLoanApproval}
                      lastInstallment={simulation.lastInstallment}
                      sumInstallments={simulation.loanTotalValue}
                      amortization={dynamicSimulation.value.amortization}
                      notaryFee={dynamicSimulation.value.notorialCosts}
                      installments={simulation.availablePaymentTerm}
                      interestRateType={simulation.adjustment}
                      installment={simulation.firstInstallment}
                      interestRate={simulation.interestRate}
                      loanAmount={simulation.maxDebtCapacity}
                    />
                  ),
                )}
              </S.SimulationsList>
              <motion.div
                layout
                animate='visible'
                initial='hidden'
                variants={fadeIn({ delay: 0.55 })}
              >
                <Box mt={2} mb={3}>
                  <Disclaimer
                    icon='alert'
                    message={
                      <>
                        <Typography.Highlight>
                          Simulação é uma previsão para ajudar na sua escolha.
                        </Typography.Highlight>{' '}
                        Apesar de refletir cenários reais, podem ocorrer
                        alterações na aprovação do financiamento.
                      </>
                    }
                  />
                </Box>
                <S.FooterWrapper mt={3} mb={5}>
                  <Typography variant='h2'>Ainda ficou em dúvida?</Typography>
                  <Typography>
                    Faça ajustes com as dicas da{' '}
                    <Typography.Highlight>Soma+</Typography.Highlight>
                  </Typography>
                  <Box my={2}>
                    <Button kind='outline' onClick={() => setModalOpen(true)}>
                      Ajustar condições
                    </Button>
                  </Box>
                  <Button.Link
                    kind='link'
                    external
                    to={process.env.REACT_APP_BOT_WHATSAPP_LINK || ''}
                    mixpanelEvent={{
                      name: 'Clicou em Preciso de ajuda',
                    }}
                  >
                    Preciso de ajuda
                  </Button.Link>
                </S.FooterWrapper>
              </motion.div>
              <ModalWindow
                visible={modalOpen}
                onClose={() => setModalOpen(false)}
              >
                <ChangeParameters onClick={closeModal} />
              </ModalWindow>
            </AnimateSharedLayout>
          </>
        )}
      {!isFetchingSimulation && !isRefetching && simulationError && (
        <S.FooterWrapper pb={5}>
          <Typography variant='h2' align='center'>
            Ops! 😵
            <br />
            Faltou pouco para alcançar o seu financiamento
          </Typography>
          <S.TextContent mt={3} mb={4}>
            <Typography color={theme.colors.text}>
              Não encontramos simulações com essas condições... Mas você pode
              dar a volta por cima! 😎 <br />
              <br />
              Não tem erro. Para melhorar o seu financiamento, tente:
              <br />
              <ul>
                <li>Somar renda com mais gente</li>
                <li>Aumentar a amortização (considere aqui o seu FGTS!)</li>
                <li>Aumentar o prazo</li>
              </ul>
            </Typography>
          </S.TextContent>
          <Button onClick={() => setModalOpen(true)}>
            Melhorar financiamento
          </Button>
          <ModalWindow visible={modalOpen} onClose={() => setModalOpen(false)}>
            <ChangeParameters
              onClick={() => {
                closeModal()
                refetch()
              }}
            />
          </ModalWindow>
        </S.FooterWrapper>
      )}
    </>
  )
}

export default Simulations

import {
  Button,
  Capitalize,
  Content,
  SendDocumentsCard,
  Typography,
  Wrapper,
  Header,
} from 'ui'
import { useContracts, useMe } from 'client'
import { isSome } from 'fp-ts/lib/Option'

import { fadeIn, theme, trackEvent } from 'lib'
import Box from '@material-ui/core/Box'

import { Contract } from 'ui/Contract'
import { CONTRACT } from 'routes'
import { mdiArrowRight } from '@mdi/js'
import * as S from './Home.styled'
import * as Loader from './ContentLoader'

const Home = () => {
  const { isFetching: isFetchingContracts, contracts } = useContracts()
  const { isFetching: isFetchingMe, proponent } = useMe()

  return (
    <Wrapper ignoreHeader>
      <Header title='Wallet Soma+'></Header>
      <Box mb={4}>
        <S.Background />
        <Content>
          {isFetchingMe && <Loader.Texts />}
          {!isFetchingMe && isSome(proponent) && (
            <>
              <Typography
                animate='visible'
                initial='hidden'
                variants={fadeIn({ delay: 0 })}
                variant='h1'
              >
                Olá{' '}
                <Capitalize>{proponent.value.name.split(' ')[0]}</Capitalize>,
              </Typography>
              <Typography
                color={theme.colors.gray2}
                animate='visible'
                initial='hidden'
                variants={fadeIn({ delay: 0.2 })}
                variant='subtitle'
              >
                Esta é sua
                <Typography.Highlight> Wallet Soma+</Typography.Highlight>. Aqui
                você acompanha tudo sobre o seu processo de financiamento
                imobiliário.
              </Typography>
            </>
          )}
          <Box mt={7}>
            {isFetchingMe && <Loader.Card />}
            {!isFetchingMe && (
              <SendDocumentsCard
                animate='visible'
                initial='hidden'
                variants={fadeIn({ delay: 0.4 })}
              />
            )}
          </Box>

          {isFetchingContracts && <Loader.ContractsSection />}
          {!isFetchingContracts && isSome(contracts) && (
            <>
              <Box mt={7}>
                <Typography
                  animate='visible'
                  initial='hidden'
                  variants={fadeIn({ delay: 0.6 })}
                  variant='h2'
                >
                  {contracts.value.length > 1
                    ? 'Seus financiamentos'
                    : 'Seu financiamento'}
                </Typography>
                <Typography
                  color={theme.colors.gray2}
                  animate='visible'
                  initial='hidden'
                  variants={fadeIn({ delay: 0.7 })}
                >
                  {contracts.value.length > 1
                    ? 'Selecione um imóvel para ver mais detalhes'
                    : 'Veja mais detalhes sobre o seu financimaneto imobiliário'}
                </Typography>
              </Box>

              <S.ContractsList
                animate='visible'
                initial='hidden'
                variants={fadeIn({ delay: 0.9 })}
                data-testid='contracts-list'
              >
                {contracts.value.map((contract) => (
                  <Box pb={3} key={contract.id}>
                    <S.CardLink
                      to={CONTRACT.replace(':id', contract.id)}
                      onClick={() => trackEvent('Acessou Detalhes do Contrato')}
                    >
                      <li>
                        <Contract
                          id={contract.id}
                          estateName={contract.property.estate.name}
                          block={contract.property.block}
                          unit={contract.property.unit}
                          status={contract.status}
                          showStatus={true}
                          footer={
                            <Button.Link
                              mixpanelEvent={{
                                name: 'Acessou Detalhes do Contrato',
                              }}
                              kind='link'
                              to={CONTRACT.replace(':id', contract.id)}
                              suffixIcon={mdiArrowRight}
                            >
                              VER MAIS
                            </Button.Link>
                          }
                        />
                      </li>
                    </S.CardLink>
                  </Box>
                ))}
              </S.ContractsList>
            </>
          )}
        </Content>
      </Box>
    </Wrapper>
  )
}

export default Home

import { HELP_CENTER_ILLUSTRATION } from 'assets'
import { ReactNode } from 'react'
import { Typography, Button } from 'ui'

import Box from '@material-ui/core/Box'
import * as S from './HelpCenter.styled'

type Props = {
  title: ReactNode
  action: string
  link: string
}

const HelpCenter = ({ link, title, action }: Props) => (
  <S.Wrapper>
    <img
      src={HELP_CENTER_ILLUSTRATION}
      alt='A ilustração mostra uma pessoa em pé segurando um círculo com um ponto de interrogação no centro.'
    />
    <Box mt={2} mb={3}>
      <Typography align='center'>{title}</Typography>
    </Box>
    <Button.Link
      mixpanelEvent={{ name: 'Clique no Link Para Falar com o Soma+' }}
      external
      to={link}
    >
      {action}
    </Button.Link>
  </S.Wrapper>
)

export { HelpCenter }

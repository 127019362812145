import { theme } from 'lib'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: ${theme.spacing[3]};
  background: transparent;
  border: 2px solid ${theme.colors.primary};
  border-radius: ${theme.radius.small};
  display: flex;
  align-items: center;
`

const Text = styled.p`
  font-size: 1.4rem;
  color: ${theme.colors.black};
  font-family: ${theme.fontFamily};
  font-weight: normal;
  margin: 0;
`

export { Wrapper, Text }

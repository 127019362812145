import styled from 'styled-components'
import { Typography } from 'ui'

const Image = styled.img`
  width: 100%;
`

const DownloadText = styled(Typography)`
  font-weight: 500;
  font-size: 1.4rem;
`

export { Image, DownloadText }

import { theme } from 'lib'
import styled from 'styled-components'

import { WrapperProps } from './Wrapper.types'

const Wrapper = styled.div<WrapperProps>`
  padding: ${(p) => (p.ignoreHeader ? 0 : theme.constants.headerHeight)} 0 0 0;
  overflow: auto;
  max-width: ${theme.constants.wrapperMaxWidth};
  margin: 0 auto;
`

export { Wrapper }

import styled from 'styled-components'
import { motion } from 'framer-motion'
import { theme } from 'lib'
import Box from '@material-ui/core/Box'

const Heading = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.div`
  display: flex;
  align-items: center;
`

const Badge = motion(styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background: ${theme.colors.red};
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 600;
  font-family: ${theme.fontFamily};
  margin-left: ${theme.spacing[1]};
`)

export { Heading, Badge, Title }

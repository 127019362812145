import { theme } from 'lib'
import styled from 'styled-components'
import { LabeledValue } from 'ui'
import { motion } from 'framer-motion'

export const Wrapper = styled.li`
  list-style: none;
  &:only-child {
    min-width: 100%;
  }
`
export const Label = styled(LabeledValue)`
  white-space: pre-line;
`

export const BankLogo = styled.img`
  margin-bottom: ${theme.spacing[3]};
`

export const CheckIcon = styled.img`
  margin-left: ${theme.spacing[1]};
  transform: translateY(2px);
`

export const ButtonWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  margin-bottom: -${theme.spacing[2]};
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

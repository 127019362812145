import { media, theme } from 'lib'
import { Typography } from 'ui'
import styled from 'styled-components'
import { CardProps } from './DocumentCard'

const Card = styled.div<CardProps>`
  background: ${theme.colors.shape01};
  border-radius: ${theme.radius.xSmall};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem;
  ${(p) =>
    p.clickable ? 'filter: drop-shadow(0px 1.5px 1px rgba(32,33,58,0.2))' : ''};
`

const Thumbnail = styled.img`
  height: 6rem;
  width: 6rem;
  border-radius: ${theme.radius.xSmall};
  object-fit: cover;
  margin-right: ${theme.spacing[2]};
`

const TruncatedName = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 36rem;

  ${media.lessThan('phone')`
    max-width: 20rem;
  `}

  ${media.lessThan('smallPhone')`
    max-width: 16rem;
  `}

  ${media.lessThan('xSmallPhone')`
    max-width: 12rem;
  `}
`

export { Card, Thumbnail, TruncatedName }

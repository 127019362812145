import { mdiAttachment } from '@mdi/js'
import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray'
import { fromNullable, Option } from 'fp-ts/lib/Option'
import { theme } from 'lib'
import { dropLast, last, toUpper } from 'ramda'

import * as S from './FileUploader.styled'

type FileType = 'jpg' | 'png' | 'jpeg' | 'pdf' | 'svg'

type Props = {
  label?: string
  text?: string
  multiple?: boolean
  testId?: string
  accept: NonEmptyArray<FileType>
  maxSize?: number
  onChange?: (files: Option<FileList>) => void
}

const FileUploader = ({
  label = 'Adicionar documentos',
  multiple = false,
  maxSize = 50,
  onChange = () => {},
  accept,
  testId = '',
}: Props) => {
  return (
    <S.Wrapper>
      <S.InputFile
        data-testid={testId}
        type='file'
        accept={accept.map(addPrefix).join(',')}
        multiple={multiple}
        onChange={(event) => onChange(fromNullable(event.target.files))}
      />
      <S.Heading>
        <S.Icon
          path={mdiAttachment}
          color={theme.colors.primary}
          size='2.4rem'
        />
        <S.Title color={theme.colors.primary}>{label}</S.Title>
      </S.Heading>
      <S.Text align='center'>
        {formatList(accept)} e o tamanho máximo é de {maxSize}mb
      </S.Text>
    </S.Wrapper>
  )
}

const addPrefix = (file: string) => `.${file}`

const formatList = (types: FileType[]) => {
  const uppercasedTypes = types.map(toUpper)

  if (types.length === 0) return

  if (types.length === 1) {
    const [fileType] = uppercasedTypes
    return `O formato aceito é ${fileType}`
  }

  if (types.length > 1) {
    const lastType = last(uppercasedTypes)
    const rest = dropLast(1, uppercasedTypes)
    return `Os formatos aceitos são ${rest.join(', ')} e ${lastType}`
  }
}

export { FileUploader }

import styled from 'styled-components'
import { Capitalize } from 'ui'

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 360px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const ProponentName = styled(Capitalize)`
  display: block;

  @media (max-width: 400px) {
    max-width: 14rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media only screen and (min-width: 400px) and (max-width: 500px) {
    max-width: 16rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export { Row, ProponentName }

import { motion } from 'framer-motion'
import { theme } from 'lib'
import styled from 'styled-components'

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const ListItem = motion(styled.li`
  list-style: none;
  margin-bottom: ${theme.spacing[2]};
`)

const RemoveButton = styled.button`
  background: transparent;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  font-size: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ErrorIcon = styled.img``

export { List, ListItem, RemoveButton, ErrorIcon }

import styled, { css } from 'styled-components'
import { theme } from 'lib'
import { Card as UICard } from 'ui'
import { transparentize } from 'polished'
import { StyledCardProps } from './StepCard'

const Card = styled(UICard)<StyledCardProps>`
  ${(props) =>
    props.status === 'warning' &&
    css`
      border: solid 2px ${theme.colors.orange};
      background-color: ${transparentize(0.75, theme.colors.orange)};
    `}

  ${(props) =>
    props.status === 'waiting' &&
    css`
      opacity: 0.7;
    `}

  ${(props) =>
    props.status === 'active' &&
    css`
      border: solid 2px ${theme.colors.primary};
      position: relative;

      &:before {
        left: ${theme.spacing[2]};
        top: -2.8rem;
        content: 'Você está aqui';
      }

      &:after {
        left: ${theme.spacing[2]};
        bottom: -3.8rem;
        content: 'Próximas etapas';
      }

      &:before,
      &:after {
        position: absolute;
        color: ${theme.colors.gray2};
        font-family: ${theme.fontFamily};
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.4rem;
      }
    `}
`

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing[1]};
`

export { Row, Card }

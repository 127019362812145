import { fadeIn, theme, trackEvent } from 'lib'
import { UPLOAD_DOCUMENTS } from 'routes'
import { DocumentCategory, DocumentGroupStatus } from 'types'
import { Button, Card, Typography } from 'ui'
import { mdiArrowRight, mdiClose, mdiCheck, mdiDotsHorizontal } from '@mdi/js'

import Icon from '@mdi/react'
import * as S from './CategoryCard.styled'

type Props = {
  status: DocumentGroupStatus
  category: DocumentCategory
  amountOfDocuments: number
  animationId: number
}
const CategoryCard = ({
  status,
  amountOfDocuments,
  category,
  animationId,
}: Props) => (
  <S.CardLink
    to={UPLOAD_DOCUMENTS.replace(':category', category)}
    onClick={() =>
      trackEvent('Acessou Upload de Documentos', {
        Categoria: titleByCategory[category],
      })
    }
  >
    <Card
      testId={testIdByCategory[category]}
      variants={fadeIn({ delay: animationId * 0.2 })}
      animate='visible'
      initial='hidden'
    >
      <Typography variant='h3'>{titleByCategory[category]}</Typography>
      <Typography color={theme.colors.gray2}>{textByStatus(status)}</Typography>
      <S.Row>
        <Button.Link
          mixpanelEvent={{
            name: 'Acessou Upload de Documentos',
            properties: { Categoria: titleByCategory[category] },
          }}
          to={UPLOAD_DOCUMENTS.replace(':category', category)}
          suffixIcon={mdiArrowRight}
          kind='link'
        >
          {labelByStatus[status.tag]}
        </Button.Link>
      </S.Row>
      {status.tag !== 'WAITING' && (
        <S.Status status={status}>
          <Icon
            color={theme.colors.white}
            path={iconByStatus[status.tag]}
            size='2.4rem'
          />
        </S.Status>
      )}
      {amountOfDocuments > 0 && <S.Counter>{amountOfDocuments}</S.Counter>}
    </Card>
  </S.CardLink>
)

const testIdByCategory = {
  IDENTIFICACAO: 'identitity-card',
  RESIDENCIA: 'residence-card',
  RENDA: 'income-card',
  ESTADO_CIVIL: 'marital-status-card',
}

const titleByCategory = {
  IDENTIFICACAO: 'Identidade',
  RESIDENCIA: 'Residência',
  RENDA: 'Renda',
  ESTADO_CIVIL: 'Estado Civil',
}

const textByStatus = (status: DocumentGroupStatus) =>
  ({
    APPROVED: 'Todos os documentos verificados',
    PENDING: 'Documentos em análise',
    REFUSED: `${
      status.tag === 'REFUSED' && status.amountOfRefused
    } documento(s) recusado(s)`,
    WAITING: 'Nenhum documento enviado',
  }[status.tag])

const labelByStatus = {
  APPROVED: 'VER MAIS',
  PENDING: 'VER MAIS',
  REFUSED: 'VERIFICAR',
  WAITING: 'ENVIAR DOCUMENTOS',
}

const iconByStatus = {
  APPROVED: mdiCheck,
  PENDING: mdiDotsHorizontal,
  REFUSED: mdiClose,
  WAITING: '',
}

export { CategoryCard }

import styled, { css } from 'styled-components'
import { theme } from 'lib'
import { motion } from 'framer-motion'

import { StyledProps } from './Card.types'

export const Card = motion(styled.div<StyledProps>`
  background: ${theme.colors.shape01};
  padding: ${theme.spacing[3]};
  border-radius: ${theme.radius.small};
  position: relative;
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}
`)

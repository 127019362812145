import { getOrElse, isSome } from 'fp-ts/lib/Option'
import { fadeIn, theme } from 'lib'
import { DOCUMENTS } from 'routes'
import {
  Header,
  FileUploader,
  Typography,
  Wrapper,
  ErrorDialog,
  ModalWindow,
} from 'ui'
import Box from '@material-ui/core/Box'
import { motion } from 'framer-motion'
import { descriptionByCategory, titleByCategory } from './documents-content'
import { RecommendedDocuments } from './RecommendedDocuments'
import { SentDocuments } from './SentDocuments'
import { SuccessDialog } from './SuccessDialog'
import { UploadItem, UploadsList } from './UploadsList'
import { useUploadDocuments } from './useUploadDocuments'
import { ContentLoader } from './ContentLoader/ContentLoader'
import { DocumentPreview } from './DocumentPreview'

const UploadDocuments = () => {
  const {
    removeFile,
    appendFile,
    uploadFiles,
    closeSuccessDialog,
    closeErrorDialog,
    openPreview,
    closePreview,
    files,
    documents,
    category,
    uploadStatus,
    previewModal,
    isLoading,
  } = useUploadDocuments()

  if (isLoading) {
    return (
      <Wrapper>
        <ContentLoader />
      </Wrapper>
    )
  }

  return (
    <Wrapper ignoreHeader>
      <Header title={titleByCategory[category]} backButtonRoute={DOCUMENTS} />
      <Box py={4} mb={2} px={3}>
        <motion.div
          animate='visible'
          initial='hidden'
          variants={fadeIn({ delay: 0 })}
        ></motion.div>

        <Typography
          animate='visible'
          initial='hidden'
          variants={fadeIn({ delay: 0.2 })}
          variant='h2'
        >
          {titleByCategory[category]}
        </Typography>

        <Typography
          animate='visible'
          initial='hidden'
          variants={fadeIn({ delay: 0.4 })}
          color={theme.colors.gray2}
        >
          {descriptionByCategory[category]}
        </Typography>
        <motion.div
          animate='visible'
          initial='hidden'
          variants={fadeIn({ delay: 0.6 })}
        >
          <Box my={4}>
            <FileUploader
              testId='documents-file-uploader'
              multiple
              onChange={appendFile}
              accept={['jpg', 'jpeg', 'png', 'pdf']}
            />
          </Box>
        </motion.div>

        <Box my={4}>
          {files.length > 0 && (
            <UploadsList
              onUpload={uploadFiles}
              amountOfFiles={files.length}
              isUploading={uploadStatus === 'uploading'}
            >
              {files.map((file, id) => (
                <UploadItem
                  key={id}
                  status={file.status}
                  name={file.name}
                  fileType={file.fileType}
                  thumbnail={getOrElse(() => '')(file.thumbnail)}
                  onRemove={() => removeFile(id)}
                />
              ))}
            </UploadsList>
          )}
        </Box>

        {isSome(documents) && documents.value.length > 0 && (
          <Box my={4}>
            <SentDocuments
              onDocumentClick={openPreview}
              documents={documents.value}
            />
          </Box>
        )}

        <Box my={4}>
          <RecommendedDocuments category={category} />
        </Box>
      </Box>
      <SuccessDialog
        visible={uploadStatus === 'success'}
        onClose={closeSuccessDialog}
      />
      <ErrorDialog
        visible={uploadStatus === 'uploadError'}
        onClose={closeErrorDialog}
        onConfirm={uploadFiles}
        confirmText='Tentar novamente'
        description='Por favor, confira se o formato e o tamanho do arquivo estão corretos.'
      />

      <ModalWindow visible={previewModal.visible} onClose={closePreview}>
        <DocumentPreview document={previewModal.document} />
      </ModalWindow>
    </Wrapper>
  )
}

export default UploadDocuments

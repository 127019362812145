import { Button, Card, Typography } from 'ui'
import { DOCUMENTS_ICON } from 'assets'

import Box from '@material-ui/core/Box'
import { MotionProps } from 'framer-motion'
import { theme, trackEvent } from 'lib'
import { DOCUMENTS } from 'routes'
import * as S from './SendDocumentsCard.styled'

const SendDocumentsCard = (props: MotionProps) => (
  <S.CardLink
    to={DOCUMENTS}
    onClick={() => trackEvent('Acessou Cofre de Documentos')}
  >
    <Card {...props}>
      <S.Header>
        <img src={DOCUMENTS_ICON} />
        <S.Title>Acelere seu processo!</S.Title>
      </S.Header>
      <Typography align='center' color={theme.colors.gray2} variant='text'>
        Envie agora mesmo os seus documentos e acelere as etapas do seu
        processo.
      </Typography>
      <Box mt={4}>
        <Button.Link
          mixpanelEvent={{ name: 'Acessou Cofre de Documentos' }}
          to={DOCUMENTS}
          block
        >
          Enviar documentos
        </Button.Link>
      </Box>
    </Card>
  </S.CardLink>
)

export { SendDocumentsCard }

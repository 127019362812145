import { theme } from 'lib'
import { DocumentType } from 'types'

const titleByCategory = {
  IDENTIFICACAO: 'Identidade',
  RENDA: 'Renda',
  RESIDENCIA: 'Residência',
  ESTADO_CIVIL: 'Estado Civil',
}

const descriptionByCategory = {
  IDENTIFICACAO:
    'Precisamos comprovar sua identidade. Adicione seus documentos e nós vamos analisá-los.',
  RENDA:
    'Precisamos comprovar sua renda. Adicione seus documentos e nós vamos analisá-los.',
  RESIDENCIA:
    'Precisamos comprovar seu endereço. Adicione seus documentos e nós vamos analisá-los.',
  ESTADO_CIVIL:
    'Precisamos comprovar seu estado civil. Adicione seus documentos e nós vamos analisá-los.',
}

const colorByStatus = {
  PENDING: theme.colors.orange,
  REFUSED: theme.colors.red,
  APPROVED: theme.colors.primary,
}

const labelByStatus = {
  PENDING: 'EM ANÁLISE',
  REFUSED: 'RECUSADO',
  APPROVED: 'APROVADO',
}

const labelByType: {
  [Key in DocumentType]: string
} = {
  CPF: 'CPF',
  CNPJ: 'CNPJ',
  CNH: 'CNH',
  RG: 'RG',
  PASSAPORTE: 'Passaporte',
  CTPS: 'CTPS',
  LUZ: 'Conta de luz',
  AGUA: 'Conta de água',
  IRPF: 'IRPF',
  HOLERITE: 'Holerite',
  TELEFONE: 'Conta de telefone',
  CERTIDAO_DE_CASAMENTO: 'Certidão de casamento',
  CERTIDAO_DE_NASCIMENTO: 'Certidão de nascimento',
}

export {
  labelByType,
  labelByStatus,
  descriptionByCategory,
  titleByCategory,
  colorByStatus,
}

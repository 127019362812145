import { motion } from 'framer-motion'
import { theme } from 'lib'
import styled from 'styled-components'
import { StyledProps } from './ProgressBar.types'

const Overlay = motion(styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: ${theme.zIndex.aboveAll + 1};
  background: ${theme.colors.white};
  padding: ${theme.spacing[4]} ${theme.spacing[3]};
  display: flex;
  flex-direction: column;
  overflow: auto;
`)

const CloseButton = styled.button`
  border: 0;
  outline: 0;
  background: 0;
  font-size: 0;
  box-shadow: 0;
  padding: 0;
  margin: 0;
`

const ProgressBar = styled.div`
  width: 100%;
  height: 0.8rem;
  border-radius: ${theme.radius.xSmall};
  background-color: ${theme.colors.gray1};
`
const Filler = styled.div<StyledProps>`
  width: ${(p) => p.percentage}%;
  height: 0.8rem;
  border-radius: ${theme.radius.xSmall};
  background-color: ${theme.colors.primary};
`

export { Overlay, CloseButton, ProgressBar, Filler }

import { get } from 'client'
import { pipe } from 'fp-ts/lib/function'
import { fold, fromNullable, map, Option } from 'fp-ts/lib/Option'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import {
  ApiResponse,
  Document,
  DocumentCategory,
  DocumentRaw,
  Nullable,
} from 'types'

type DocumentsResponse = ApiResponse<DocumentRaw[]>

type HookParams = {
  category: Option<DocumentCategory>
  onSuccess?: (data: Option<Document[]>) => void
}

const useDocuments = ({ category, onSuccess }: HookParams) => {
  const handleSuccess = useCallback(
    (result: DocumentsResponse) => {
      if (onSuccess) {
        const documents = normalizeDocuments(result)
        onSuccess(documents)
      }
    },
    [onSuccess],
  )
  const { data: result, isLoading, isFetching, refetch } = useQuery({
    queryKey: createQueryKey(category),
    queryFn: () => fetchDocs(category),
    refetchOnWindowFocus: false,
    onSuccess: handleSuccess,
  })

  return {
    refetch,
    isFetching,
    isLoading,
    documents: normalizeDocuments(result),
  }
}

const normalizeDocument = (document: DocumentRaw): Document => ({
  ...document,
  reason: fromNullable(document.reason),
  type: fromNullable(document.type),
})

const normalizeDocuments = (result: Nullable<DocumentsResponse>) =>
  pipe(
    result,
    fromNullable,
    map((result) => result.data),
    map((documents) => documents.map(normalizeDocument)),
  )

const createQueryKey = (category: Option<DocumentCategory>) =>
  pipe(
    category,
    fold(
      () => ['documents'],
      (category) => ['documents', category],
    ),
  )

const fetchDocs = (category: Option<DocumentCategory>) =>
  pipe(
    category,
    fold(
      () => '/document_uploads',
      (value) => `/document_uploads?category=${value}`,
    ),
    (url) => get<DocumentsResponse>(url),
  )

export { useDocuments }

import { motion } from 'framer-motion'
import { fadeIn, theme } from 'lib'
import { Card, LabeledValue, Typography, HideableValue } from 'ui'

import Box from '@material-ui/core/Box'
import * as S from './Restriction.styled'

type Props = {
  name: string
  proponentName: string
  value: string
  showValues: boolean
  animationDelay: number
}

const Restriction = ({
  name,
  value,
  proponentName,
  showValues,
  animationDelay: delay,
}: Props) => (
  <motion.div animate='visible' initial='hidden' variants={fadeIn({ delay })}>
    <Box mb={2}>
      <Card>
        <Box mb={2}>
          <HideableValue visible={showValues}>
            <Typography variant='h3'>{name}</Typography>
          </HideableValue>
        </Box>

        <S.Row>
          <LabeledValue
            labelSize='small'
            captionSize='small'
            captionColor={theme.colors.primary}
            variant='caption'
            label='Proponente'
          >
            <HideableValue visible={showValues}>
              <S.ProponentName>{proponentName}</S.ProponentName>
            </HideableValue>
          </LabeledValue>

          <LabeledValue
            labelSize='small'
            captionSize='small'
            captionColor={theme.colors.primary}
            variant='caption'
            label='Valor'
          >
            <HideableValue visible={showValues}>R$ {value}</HideableValue>
          </LabeledValue>
        </S.Row>
      </Card>
    </Box>
  </motion.div>
)

export { Restriction }

import { Option } from 'fp-ts/lib/Option'
import { Overwrite } from 'utility-types'
import * as Eq from 'fp-ts/lib/Eq'

export type DocumentCategory =
  | 'IDENTIFICACAO'
  | 'RENDA'
  | 'RESIDENCIA'
  | 'ESTADO_CIVIL'

export type DocumentStatus = 'PENDING' | 'APPROVED' | 'REFUSED'

export type FileType = 'jpg' | 'png' | 'jpeg' | 'pdf'

export type DocumentType =
  | 'CPF'
  | 'CNPJ'
  | 'CNH'
  | 'RG'
  | 'PASSAPORTE'
  | 'CTPS'
  | 'LUZ'
  | 'AGUA'
  | 'IRPF'
  | 'HOLERITE'
  | 'TELEFONE'
  | 'CERTIDAO_DE_CASAMENTO'
  | 'CERTIDAO_DE_NASCIMENTO'

export type DocumentRaw = {
  category: DocumentCategory
  id: string
  reason?: string
  remotePath: string
  status: DocumentStatus
  type?: DocumentType
  fileType: FileType
}

export type Document = Overwrite<
  DocumentRaw,
  {
    reason: Option<string>
    type: Option<DocumentType>
  }
> & { fileType: FileType }

export type DocumentGroupStatus =
  | { tag: 'PENDING' }
  | { tag: 'APPROVED' }
  | { tag: 'WAITING' }
  | { tag: 'REFUSED'; amountOfRefused: number }

export type DocumentGroup = {
  category: DocumentCategory
  status: DocumentGroupStatus
  amountOfDocuments: number
}

export const DocumentEq = {
  withCategory: Eq.fromEquals(
    (a: Document, b: Document) => a.category === b.category,
  ),
}

export const DOCUMENT_CATEGORIES: DocumentCategory[] = [
  'IDENTIFICACAO',
  'RENDA',
  'RESIDENCIA',
  'ESTADO_CIVIL',
]

/* eslint no-undef: "off" */
export const isValidCategory = (
  category: string,
): category is DocumentCategory =>
  DOCUMENT_CATEGORIES.includes(category as DocumentCategory)

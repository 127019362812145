import styled from 'styled-components'

import { Props } from './HideableValue.types'

const HideableValue = styled.div<Props>`
  position: relative;

  &:after {
    left: 0;
    top: 0;
    content: ' ';
    position: absolute;
    height: 100%;
    background: linear-gradient(90deg, #d6e4f4 1.9%, #ddecff 81.9%);
    border-radius: 4px;
    transition: 200ms width;
    width: ${(props) => (props.visible ? 0 : '100%')};
  }
`

export { HideableValue }

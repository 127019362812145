import { useEffect, useState } from 'react'
import { put, useSimulationParams } from 'client'
import { useParams } from 'react-router-dom'
import { isSome } from 'fp-ts/lib/Option'
import { SimulationParams } from 'types'
import {
  normalizeSimulationParams,
  normalizeSimulationParamsBody,
} from 'client/normalizations'
import { useForm } from 'react-hook-form'
import { ErrorResponse, errorResponse } from 'client/errorResponse'
import { AxiosError } from 'axios'

type UpdateStatus = 'initial' | 'success' | 'error'
type onClick = () => void

const useChangeParameters = (onClick: onClick) => {
  const { id: contractId } = useParams<{ id: string }>()
  const {
    simulationParams: simulationParamsResponse,
    isLoading,
    refetch,
  } = useSimulationParams(contractId)

  const [simulationParams, setSimulationParams] = useState<SimulationParams>()
  const [proponentYear, setProponentYear] = useState(0)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [isError, setIsError] = useState<UpdateStatus>('initial')

  const {
    register,
    handleSubmit,
    control,
    reset,
    setError,
  } = useForm<SimulationParams>({
    defaultValues: simulationParams,
  })

  const onSubmit = async (updatedParams: SimulationParams) => {
    const { id } = updatedParams
    setIsUpdating(true)

    try {
      const { data } = await put(
        `/simulation_params/${id}`,
        normalizeSimulationParamsBody(updatedParams),
      )
      handleSimulationParams(normalizeSimulationParams(data.data))
      setIsUpdating(false)
      onClick()
    } catch (error: unknown) {
      const axiosError = error as AxiosError
      const response = errorResponse(axiosError)
      handleUpdateErrors(response)
      setIsUpdating(false)
    }
  }

  useEffect(() => {
    if (isSome(simulationParamsResponse)) {
      setSimulationParams(simulationParamsResponse.value)
      handleProponentYears(simulationParamsResponse.value?.paymentDeadline)
      reset(simulationParamsResponse.value)
    }
  }, [isLoading])

  useEffect(() => {
    handleProponentYears(simulationParams?.paymentDeadline)
  }, [simulationParams?.paymentDeadline])

  const handleSimulationParams = (simulationParams: SimulationParams) => {
    setSimulationParams(simulationParams)
  }

  const handleProponentYears = (
    paymentDeadlineInYears: number | null | undefined,
  ) => {
    const years = paymentDeadlineInYears || 0
    const proponentMaxYear = 80 - years
    setProponentYear(proponentMaxYear)
  }

  const handleUpdateErrors = ({ field, message, status }: ErrorResponse) => {
    if (status === 422) {
      return setError(field, { message }, { shouldFocus: true })
    }

    return setIsError('error')
  }

  return {
    simulationParams,
    proponentYear,
    isUpdating,
    isLoading,
    isError,
    setIsError,
    register,
    handleSubmit,
    onSubmit,
    control,
    handleProponentYears,
    setError,
    refetch,
  }
}

export { useChangeParameters }

import { generateMedia } from 'styled-media-query'
import { theme } from './theme'

const media = generateMedia({
  xSmallPhone: '320px',
  smallPhone: '380px',
  phone: '480px',
  tablet: theme.constants.wrapperMaxWidth,
  smallDesktop: '1100px',
  mediumDesktop: '1350px',
  largeDesktop: '1600px',
  largestDesktop: '1850px',
})

export default media

import Icon from '@mdi/react'
import { FileType } from 'types'
import { Spinner } from 'ui'
import { mdiCheck, mdiClose } from '@mdi/js'
import { fadeIn, theme } from 'lib'

import { ERROR_CIRCLE_ICON } from 'assets'
import Box from '@material-ui/core/Box'
import { DocumentCard } from '../DocumentCard'
import { LocalFileStatus } from '../types'
import * as S from './UploadsList.styled'

type Props = {
  name: string
  fileType: FileType
  thumbnail: string
  onRemove: () => void
  status: LocalFileStatus
}

const UploadItem = ({ name, fileType, thumbnail, onRemove, status }: Props) => (
  <S.ListItem
    animate='visible'
    initial='hidden'
    variants={fadeIn({ delay: 0 })}
  >
    <DocumentCard name={name} fileType={fileType} url={thumbnail}>
      <Box pr={2} alignContent='center' display='flex'>
        {status === 'sending' && <Spinner size='24' />}
        {status === 'error' && <S.ErrorIcon src={ERROR_CIRCLE_ICON} />}
        {status === 'success' && (
          <Icon path={mdiCheck} color={theme.colors.primary} size='1.8rem' />
        )}
        {['error', 'initial'].includes(status) && (
          <S.RemoveButton onClick={onRemove}>
            <Icon path={mdiClose} color={theme.colors.black} size='1.8rem' />
          </S.RemoveButton>
        )}
      </Box>
    </DocumentCard>
  </S.ListItem>
)

export { UploadItem }

import styled from 'styled-components'
import InputMask from 'react-number-format'
import { theme } from 'lib'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: initial;
`

const Input = styled(InputMask)`
  width: 100%;
  font-family: ${theme.fontFamily};
  font-size: 1.4rem;
  padding: ${theme.spacing[1]} ${theme.spacing[2]};
  border: 1px solid ${theme.colors.gray1};
  border-radius: 4px;
  text-decoration: none;
  outline-color: ${(props) =>
    props.error ? theme.colors.red : theme.colors.primary};
`

export { Wrapper, Input }

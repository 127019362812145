import mitt from 'mitt'
import { QueryClient } from 'react-query'
import { AxiosError } from 'axios'

const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR'
const REQUEST_ERROR = 'REQUEST_ERROR'

const queryClientEmitter = mitt()

const onError = (err: unknown) => {
  const axiosError = err as AxiosError

  if (axiosError.response?.status === 401) {
    queryClientEmitter.emit(UNAUTHORIZED_ERROR)
  } else {
    queryClientEmitter.emit(REQUEST_ERROR, axiosError)
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 120000,
      useErrorBoundary: false,
      onError,
    },
    mutations: {
      useErrorBoundary: false,
    },
  },
})

export { queryClient, queryClientEmitter, UNAUTHORIZED_ERROR, REQUEST_ERROR }

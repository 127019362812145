import { useContract } from 'client'
import { pipe } from 'fp-ts/lib/function'
import { map, Option } from 'fp-ts/lib/Option'
import { useParams } from 'react-router-dom'
import { Contract, CreditRestriction, Proponent } from 'types'
import { PreAnalysisRestriction } from '../types'

const usePreAnalysis = () => {
  const { id } = useParams<{ id: string }>()
  const { contract, isFetching } = useContract(id)

  return {
    isFetching,
    restrictions: extractRestrictions(contract),
  }
}

export const extractRestrictions = (contract: Option<Contract>) =>
  pipe(
    contract,
    map((contract) => contract.proponents),
    map(mergeRestrictions),
  )

export const mergeRestrictions = (proponents: Proponent[]) =>
  proponents.reduce((restrictions: PreAnalysisRestriction[], proponent) => {
    const proponentRestrictions = proponent.creditRestrictions.reduce(
      transformRestriction(proponent),
      [],
    )

    return [...restrictions, ...proponentRestrictions]
  }, [])

export const transformRestriction = (proponent: Proponent) => (
  proponentRestrictions: PreAnalysisRestriction[],
  restriction: CreditRestriction,
) => {
  return [
    ...proponentRestrictions,
    {
      ...restriction,
      proponentName: proponent.name,
    },
  ]
}

export { usePreAnalysis }

import { useQuery } from 'react-query'
import { ContractsDataResponse, Nullable } from 'types'
import { fromNullable, map } from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { normalizeContract } from 'client/normalizations'
import { get } from 'client'

type HookConfig = {
  enabled?: boolean
}

export const useContracts = (config?: HookConfig) => {
  const { data: contractsData, refetch, isFetching, isError } = useQuery({
    queryKey: 'contracts',
    queryFn: () => get<ContractsDataResponse>('/contracts'),
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  })

  return {
    isFetching,
    isError,
    refetch,
    contracts: normalizeContractsResponse(contractsData),
  }
}

const normalizeContractsResponse = (result: Nullable<ContractsDataResponse>) =>
  pipe(
    result,
    fromNullable,
    map((result) => result.data),
    map((data) => data.map(normalizeContract)),
  )

import styled from 'styled-components'
import { media, theme } from 'lib'
import { Typography } from 'ui/Typography'

const Title = styled(Typography)`
  font-size: 1.6rem;
  font-weight: bold;
`

const Image = styled.img`
  position: absolute;
  left: ${theme.spacing[3]};
  top: ${theme.spacing[4]};
  ${media.lessThan('smallPhone')`
    display: none;
  `}
`

export { Title, Image }

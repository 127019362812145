import * as S from './BottomNav.styled'

export type Props = {
  to: string
  icon: string
  description: string
  active: boolean
  onClick?: () => void
}

const BottomNavLink = ({ to, icon, description, active, onClick }: Props) => {
  return (
    <S.NavLink active={active ? 'true' : 'false'} to={to} onClick={onClick}>
      <img src={icon} />
      {description}
    </S.NavLink>
  )
}

export { BottomNavLink }

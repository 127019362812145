import { ChangeEventHandler, RefObject } from 'react'
import * as S from './TextInput.styled'

type Props = {
  placeholder?: string
  name?: string
  mask?: string
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined
  disabled?: boolean
  value?: string
  isAllowed?: ({ value }: { value: number }) => boolean
  error?: boolean
  getInputRef?: RefObject<HTMLHeadingElement>
  setFocus?: boolean | void
}

const TextInput = ({
  placeholder = '',
  name = '',
  mask = '',
  onChange = () => {},
  disabled = false,
  value = '',
  isAllowed = () => true,
  error,
  getInputRef,
  setFocus,
}: Props) => (
  <S.Input
    type='text'
    prefix='R$'
    thousandSeparator='.'
    decimalSeparator=','
    allowDecimalSeparator
    decimalScale={2}
    fixedDecimalScale
    isAllowed={isAllowed}
    placeholder={placeholder}
    name={name}
    mask={mask}
    maskPlaceholder={null}
    onChange={onChange}
    disabled={disabled}
    value={value}
    error={error}
    getInputRef={getInputRef}
    setFocus={setFocus}
  />
)

export { TextInput }

import { useContract } from 'client'
import { isSome } from 'fp-ts/lib/Option'
import { Timeline, Typography } from 'ui'
import { useParams } from 'react-router-dom'
import { fadeIn, theme } from 'lib'
import { useSetPreviousRoute } from 'lib/navigation'
import Box from '@material-ui/core/Box'
import { motion } from 'framer-motion'
import { StepCard } from './StepCard'
import { ContentLoader } from './ContentLoader'

const ContractTimeline = () => {
  useSetPreviousRoute('/')
  const { id } = useParams<{ id: string }>()
  const { isLoading, contractSteps } = useContract(id)

  if (isLoading) {
    return <ContentLoader />
  }

  if (isSome(contractSteps)) {
    return (
      <>
        <Box mb={4}>
          <Typography
            animate='visible'
            initial='hidden'
            variants={fadeIn({ delay: 0 })}
            variant='h2'
          >
            Status
          </Typography>
          <Typography
            animate='visible'
            initial='hidden'
            variants={fadeIn({ delay: 0.15 })}
            color={theme.colors.gray2}
          >
            Estamos acompanhando e atualizando o status do seu processo
            constantemente
          </Typography>
        </Box>
        <motion.div
          animate='visible'
          initial='hidden'
          variants={fadeIn({ delay: 0.35 })}
        >
          <Box mt={4}>
            <Timeline orientation='vertical'>
              {contractSteps.value.map((item, id) => (
                <Timeline.Item key={id} variant={item.variant}>
                  <StepCard
                    status={item.variant}
                    title={item.title}
                    description={item.description}
                    link={item.link}
                  />
                </Timeline.Item>
              ))}
            </Timeline>
          </Box>
        </motion.div>
      </>
    )
  }

  return null
}

export default ContractTimeline

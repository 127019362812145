import styled from 'styled-components'
import { theme } from 'lib'
import { motion } from 'framer-motion'
import { SvgIcon } from '@material-ui/core'
import { Link } from 'react-router-dom'

const ContractsList = styled(motion.ul)`
  margin: ${theme.spacing[3]} 0 0 0;
  padding: 0;
  list-style: none;

  > li {
    margin-bottom: ${theme.spacing[2]};
  }
`

const ArrowIcon = styled(SvgIcon)`
  position: absolute;
  top: 50%;
  right: 2.4rem;
  width: ${theme.spacing[3]} !important;
  height: ${theme.spacing[3]} !important;
  color: ${theme.colors.blackBlue};
  -o-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
`

const CardLink = styled(Link)`
  text-decoration: none;
`

export { ContractsList, ArrowIcon, CardLink }

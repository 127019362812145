import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { theme } from 'lib'
import { match } from 'ts-pattern'

import { transparentize } from 'polished'
import { isSome } from 'fp-ts/lib/Option'

import LOCKED_ICON from './assets/locked.svg'
import CHECK_ICON from './assets/check.svg'
import WARNING_ICON from './assets/warning.svg'
import {
  CustomStepConfig,
  TimelineItemProps,
  TimelineOrientation,
  TimelineProps,
  TimelineVariant,
} from './Timeline.types'

type StepMixinConfig = {
  icon: string
  stepColor: string
  ligatureColor: string
}

const stepMixin = ({
  icon,
  stepColor,
  ligatureColor,
}: StepMixinConfig) => () => css`
  &:after {
    background: ${ligatureColor};
  }

  > .timeline-tail {
    > span {
      background-color: ${stepColor};
    }

    > span:after {
      content: ' ';
      background: url(${icon}) no-repeat center;
      background-size: cover;
    }
  }
`

const customStepMixin = ({
  borderColor,
  color,
  ligatureColor,
  icon,
}: CustomStepConfig) => css`
  &:after {
    background-color: ${ligatureColor};
  }

  > .timeline-tail {
    > span {
      background-color: ${color};
      ${isSome(borderColor) && `border: solid 3px ${borderColor.value}`};
    }

    > span:after {
      ${isSome(icon) &&
      css`
        content: ' ';
        background: url(${icon.value});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      `}
    }
  }
`

const activeStepMixin = () => css`
  &:after {
    background-color: ${theme.colors.gray1};
  }

  > .timeline-tail > span {
    background: ${theme.colors.white};
    border: solid 3px ${theme.colors.primary};
  }
`

const waitingStepMixin = (orientation: TimelineOrientation) => css`
  &:after {
    background-color: ${theme.colors.gray1};
  }

  > .timeline-tail > span {
    background: ${theme.colors.white};
    border: solid 3px ${theme.colors.gray1};

    ${orientation === 'vertical' &&
    css`
      &:after {
        content: ' ';
        background: url(${LOCKED_ICON});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    `}
  }
`

const createStylesByVariant = (props: TimelineItemProps) =>
  match<TimelineVariant, FlattenSimpleInterpolation>(props.variant)
    .with(
      'done',
      stepMixin({
        icon: CHECK_ICON,
        stepColor: theme.colors.primary,
        ligatureColor: theme.colors.primary,
      }),
    )
    .with(
      'warning',
      stepMixin({
        icon: WARNING_ICON,
        stepColor: theme.colors.orange,
        ligatureColor: transparentize(0.55, theme.colors.orange),
      }),
    )
    .with('waiting', () => waitingStepMixin(props.orientation || 'vertical'))
    .with('active', activeStepMixin)
    .with({ type: 'custom' }, ({ config }) => customStepMixin(config))
    .run()

const Wrapper = styled.div<TimelineProps>`
  display: flex;
  ${(props) =>
    props.orientation === 'vertical' &&
    css`
      flex-direction: column;
    `}

  ${(props) =>
    props.orientation === 'horizontal' &&
    css`
      height: 2rem;
      justify-content: space-between;
      align-items: center;
    `}
`

const Item = styled.div<TimelineItemProps>`
  ${(props) =>
    props.orientation === 'horizontal' &&
    css`
      position: relative;
      height: 2rem;
      ${createStylesByVariant}
      > .timeline-tail > span {
        position: absolute;
        border-radius: 50%;
        z-index: ${theme.zIndex.top};
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        height: 2.4rem;

        &:after {
          content: ' ';
          height: 1.4rem;
          width: 1.4rem;
        }
      }

      &:not(:last-child) {
        flex-grow: 2;
        > .timeline-tail > span {
          left: -0.3rem;
        }

        &:after {
          content: ' ';
          position: absolute;
          height: 0.4rem;
          width: 100%;
          top: 50%;
        }
      }

      &:last-child {
        > .timeline-tail > span {
            right: -0.5rem;
          }
        }
      }
    `}

  ${(props) =>
    props.orientation === 'vertical' &&
    css`
      display: flex;
      position: relative;
      ${createStylesByVariant}

      > .timeline-tail {
        margin-right: ${theme.spacing[3]};
        position: relative;
        width: 2.8rem;

        > span {
          position: absolute;
          width: 2.8rem;
          height: 2.8rem;
          border-radius: 50%;
          top: 0.5rem;
          z-index: ${theme.zIndex.top};
          display: flex;
          justify-content: center;
          align-items: center;
        }

        > span:after {
          content: ' ';
          height: 1.6rem;
          width: 1.6rem;
        }
      }

      &:after {
        content: ' ';
        position: absolute;
        width: 0.3rem;
        bottom: 0;
        left: 1.4rem;
        margin-left: -0.1rem;
        bottom: -0.5rem;
      }

      &:not(:last-child):after {
        height: 100%;
      }
    `}
`

const TimelineChildren = styled.div`
  flex-grow: 3;
  margin-bottom: 4.2rem;
  width: 100%;
`

export { Wrapper, Item, TimelineChildren }

import { media, theme } from 'lib'
import Box from '@material-ui/core/Box'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Button } from 'ui'

export const Wrapper = styled.div`
  max-width: ${theme.constants.wrapperMaxWidth};
  margin: 0 auto;
`

export const ContentWrapper = styled.div`
  background: ${theme.colors.background};
  overflow: auto;
  width: 100%;
  border-radius: ${theme.radius.large} ${theme.radius.large} 0 0;
  position: relative;
  top: -5rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const Content = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  border-radius: ${theme.radius.large} ${theme.radius.large} 0 0;
  box-shadow: -4px -4px 8px #f2f5f8;
  padding: ${theme.spacing[5]} 0;
`

export const SimulationsList = styled(motion.ul)`
  display: flex;
  overflow: auto;
  padding: 0 ${theme.spacing[3]} ${theme.spacing[2]} ${theme.spacing[3]};
  margin: 0 -${theme.spacing[4]};

  > *:not(:only-child) {
    margin-right: ${theme.spacing[3]};
  }
  ::-webkit-scrollbar {
    height: 4px;
  }
  ${media.lessThan('tablet')`
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  `}
`

export const LtvWrapper = styled(Box)`
  display: flex;
`

export const CheckIcon = styled.img`
  width: ${theme.spacing[3]};
  height: ${theme.spacing[3]};
  align-items: center;
  justify-content: center;
  transform: translateY(4px);
`
export const ButtonWrapper = styled(motion.div)`
  margin-bottom: ${theme.spacing[2]};
  text-align: left;
`

export const DropdownButton = styled(Button)`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2rem;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const FooterWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
`

export const TextContent = styled(Box)`
  text-align: left;
`

import { DOCUMENTS_ICON } from 'assets'
import { theme } from 'lib'
import { DOCUMENTS } from 'routes'
import { Button, Dialog, Typography } from 'ui'
import Box from '@material-ui/core/Box'

import * as S from './SuccessDialog.styled'

type Props = {
  visible: boolean
  onClose: () => void
}

const SuccessDialog = ({ visible, onClose }: Props) => (
  <Dialog visible={visible} onClose={onClose}>
    <Box display='flex' alignItems='center' flexDirection='column'>
      <S.Image src={DOCUMENTS_ICON} />
      <Box my={2} display='flex' alignContent='center' justifyContent='center'>
        <S.Title align='center' color={theme.colors.primary}>
          Documentos enviados!
        </S.Title>
      </Box>
      <Typography align='center' color={theme.colors.gray2}>
        Já enviou toda a documentação necessária? Acompanhe sua pré-análise aqui
        na Wallet.
      </Typography>
      <Box mt={2} justifyContent='center' display='flex'>
        <Button.Link kind='link' to={DOCUMENTS}>
          Entendi
        </Button.Link>
      </Box>
    </Box>
  </Dialog>
)

export { SuccessDialog }

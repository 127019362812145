import Loader from 'react-content-loader'
import { theme } from 'lib'
import Box from '@material-ui/core/Box'

const Header = () => (
  <Loader
    speed={2}
    width={100}
    height={137}
    backgroundColor={theme.colors.loaderBackground}
    foregroundColor={theme.colors.loaderForeground}
    style={{ width: '100%', height: '137px' }}
  >
    <rect
      x='0'
      y='0'
      rx={theme.radius.small}
      ry={theme.radius.small}
      width='6.0rem'
      height='3.5rem'
    />
    <rect
      x='0'
      y='52'
      rx={theme.radius.small}
      ry={theme.radius.small}
      width='10.0rem'
      height='3.5rem'
    />

    <rect
      x='0'
      y='102'
      rx={theme.radius.small}
      ry={theme.radius.small}
      width='100%'
      height='3.5rem'
    />
  </Loader>
)

const Uploader = () => (
  <Loader
    speed={2}
    width={100}
    height={140}
    backgroundColor={theme.colors.loaderBackground}
    foregroundColor={theme.colors.loaderForeground}
    style={{ width: '100%', height: '140px' }}
  >
    <rect
      x='0'
      y='0'
      rx={theme.radius.small}
      ry={theme.radius.small}
      width='100%'
      height='14.0rem'
    />
  </Loader>
)

const Documents = () => (
  <Loader
    speed={2}
    width={100}
    height={230}
    backgroundColor={theme.colors.loaderBackground}
    foregroundColor={theme.colors.loaderForeground}
    style={{ width: '100%', height: '230px' }}
  >
    <rect
      x='0'
      y='0'
      rx={theme.radius.small}
      ry={theme.radius.small}
      width='10.0rem'
      height='3.5rem'
    />

    <rect
      x='0'
      y='52'
      rx={theme.radius.small}
      ry={theme.radius.small}
      width='100%'
      height='8.0rem'
    />
    <rect
      x='0'
      y='147'
      rx={theme.radius.small}
      ry={theme.radius.small}
      width='100%'
      height='8.0rem'
    />
  </Loader>
)

const ContentLoader = () => (
  <Box px={3} data-testid='uploads-content-loader'>
    <Box py={2}>
      <Header />
    </Box>
    <Box py={4}>
      <Uploader />
    </Box>
    <Box my={4}>
      <Documents />
    </Box>
    <Box my={4}>
      <Documents />
    </Box>
  </Box>
)

export { ContentLoader }

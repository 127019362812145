export const CONTRACT = '/contracts/:id'
export const SIMULATIONS = '/simulations'
export const PRE_ANALYSIS = '/pre-analysis'
export const EXPIRED_SESSION = '/expired-session'
export const DOCUMENTS = '/documents'
export const UPLOAD_DOCUMENTS = '/documents/upload/:category'
export const CONFIRM_PHONENUMBER = '/confirm-phonenumber/:token'

export const isPublicRoute = (path: string) =>
  [/confirm-phonenumber/].some((route) => route.test(path))

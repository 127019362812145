import styled from 'styled-components'
import { motion } from 'framer-motion'
import { theme } from 'lib'
import { Badge } from 'ui'

const List = styled.ul`
  margin: ${theme.spacing[3]} 0 0 0;
  padding: 0;
`

const AccordionList = motion(styled.ul`
  margin: ${theme.spacing[1]} 0 ${theme.spacing[3]} 0;
  padding: 0;
`)

const ListItem = motion(styled.li`
  list-style: none;
  padding-left: 1.6rem;
  position: relative;
  margin-bottom: ${theme.spacing[3]};
  &:after {
    position: absolute;
    top: 0.6rem;
    left: 0;
    content: ' ';
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: ${theme.colors.primary};
  }
`)

const RecommendedBadge = styled(Badge)`
  position: absolute;
  top: -0.2rem;
  right: 0;
`

export { AccordionList, List, ListItem, RecommendedBadge }

import { theme } from 'lib'
import { ReactNode } from 'react'
import { Optional } from 'utility-types'

import * as S from './Badge.styled'
import { StyledProps } from './Badge.types'

type Props = Optional<StyledProps> & {
  children: ReactNode
  className?: string
}

const Badge = ({
  children,
  size = 'medium',
  color = theme.colors.blackBlue,
  variant = 'default',
  className,
}: Props) => (
  <S.Badge className={className} variant={variant} size={size} color={color}>
    {children}
  </S.Badge>
)

export { Badge }

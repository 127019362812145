import { theme } from 'lib'
import { ReactNode } from 'react'

import * as S from './LabeledValue.styled'
import { Size, Variant } from './LabeledValue.types'

type Props = {
  label: string
  children: ReactNode
  labelSize?: Size
  captionSize?: Size
  variant?: Variant
  captionColor?: string
  labelColor?: string
}

const LabeledValue = ({
  captionColor = theme.colors.blackBlue,
  labelColor = theme.colors.gray2,
  variant = 'default',
  labelSize = 'medium',
  captionSize = 'medium',
  label,
  children,
}: Props) => (
  <S.Wrapper captionColor={captionColor} variant={variant}>
    <S.Label color={labelColor} size={labelSize}>
      {label}
    </S.Label>
    <S.Value size={captionSize}>{children}</S.Value>
  </S.Wrapper>
)

export { LabeledValue }

import { ChangeEvent } from 'react'
import { Typography } from 'ui'
import * as S from './DiscreteSlider.styled'

type Props = {
  min: number
  max: number
  defaultValue?: number
  disabled?: boolean
  label?: string
  value?: number
  onChange?: (event: ChangeEvent<unknown>, value: number | number[]) => void
}

const DiscreteSlider = ({
  min,
  max,
  defaultValue = min,
  disabled = false,
  label = '',
  value = defaultValue,
  onChange = () => {},
}: Props) => (
  <S.Wrapper>
    <S.StyledSlider
      defaultValue={defaultValue}
      aria-labelledby='discrete-slider'
      step={1}
      min={min}
      max={max}
      disabled={disabled}
      valueLabelDisplay='on'
      onChange={onChange}
      value={value}
    ></S.StyledSlider>
    <Typography>{label}</Typography>
  </S.Wrapper>
)

export { DiscreteSlider }

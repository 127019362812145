import { theme } from 'lib'
import styled from 'styled-components'

const Layout = styled.div`
  height: 100%;
  width: 100%;
  max-width: ${theme.constants.wrapperMaxWidth};
  margin: 0 auto;
  overflow: auto;
  padding: ${theme.constants.headerHeight} 0 ${theme.constants.bottomNavHeight}
    0;
`

export { Layout }

import { Button, Typography, TimelineVariant } from 'ui'
import { theme } from 'lib'
import { isSome, Option } from 'fp-ts/lib/Option'
import { mdiArrowRight } from '@mdi/js'

import * as S from './StepCard.styled'

export type StepLinkConfig = {
  label: string
  href: string
  external?: boolean
}

type Props = {
  title: string
  description: string
  link: Option<StepLinkConfig>
  status: TimelineVariant
}

export type StyledCardProps = Pick<Props, 'status'>

const StepCard = ({ status, title, description, link }: Props) => {
  return (
    <S.Card status={status}>
      <Typography
        color={colorByStatus(status, theme.colors.primary)}
        variant='h3'
      >
        {title}
      </Typography>

      <Typography color={colorByStatus(status, theme.colors.gray2)}>
        {description}
      </Typography>

      {status !== 'waiting' && isSome(link) && (
        <S.Row>
          <Button.Link
            mixpanelEvent={{ name: `Acessou ${title} pela Timeline` }}
            external={link.value.external}
            variant={status === 'warning' ? 'warning' : 'primary'}
            suffixIcon={mdiArrowRight}
            kind='link'
            to={link.value.href}
          >
            {link.value.label}
          </Button.Link>
        </S.Row>
      )}
    </S.Card>
  )
}

const colorByStatus = (status: TimelineVariant, color: string) =>
  status === 'warning' ? theme.colors.orange : color

export default StepCard

import { AxiosError } from 'axios'

type Fields =
  | 'paymentDeadline'
  | 'id'
  | 'incomePrimary'
  | 'contractId'
  | 'amortization'
  | 'includeNotorialCosts'

export type ErrorResponse = {
  field: Fields
  message: string
  status: number
}

type StatusOption = {
  [key: number]: () => ErrorResponse
}

type Errors = {
  [key: string]: number
}

const errorResponse = (error: AxiosError) => {
  const data: Errors = error.response?.data

  const statusHttp: StatusOption = {
    422: () => verifyErrorsData(data),
    500: () => handleGenericError(),
  }

  const response = data?.status

  if (response === undefined) return handleGenericError()

  return statusHttp[response]()
}

const verifyErrorsData = (data: Errors) => {
  const errorKeys = Object.keys(data.errors)

  if (errorKeys.includes('amortizationInCents')) {
    const response: ErrorResponse = {
      field: 'amortization',
      message: 'Deve ser menor que o valor a ser financiado',
      status: 422,
    }

    return response
  }

  return handleGenericError()
}

const handleGenericError = () => {
  const response: ErrorResponse = {
    field: 'id',
    message: 'Não foi possível alterar os parâmetros',
    status: 500,
  }

  return response
}

export { errorResponse }

import { useContracts, useMe } from 'client'
import { pipe } from 'fp-ts/lib/function'
import { fromNullable, isSome, map } from 'fp-ts/lib/Option'
import mixpanel, { Dict } from 'mixpanel-browser'
import { useCallback } from 'react'

const mixPanelToken = fromNullable(process.env.REACT_APP_MIXPANEL_TOKEN)

const trackEvent = (eventName: string, props: Dict = {}) => {
  if (isSome(mixPanelToken)) {
    mixpanel.track(`Wallet: ${eventName}`, props)
  }
}

const useIdentifyUser = () => {
  const { refetch: fetchUser } = useMe({
    enabled: false,
  })
  const { refetch: fetchContracts } = useContracts({
    enabled: false,
  })

  const identify = useCallback(async () => {
    const { data: userResult } = await fetchUser()
    const { data: contractsResult } = await fetchContracts()

    const maybeEstates = pipe(
      contractsResult,
      fromNullable,
      map((result) => result.data),
      map((contracts) =>
        contracts.map((c) => c.property.estate.name).join(', '),
      ),
    )

    const maybeUser = pipe(
      userResult,
      fromNullable,
      map((result) => result.data),
    )

    if (isSome(maybeUser)) {
      const { name, email, id, birthDate, creditRestrictions } = maybeUser.value
      if (isSome(mixPanelToken)) {
        mixpanel.people.set({
          $name: name,
          $email: email,
          Empreendimentos: isSome(maybeEstates) ? maybeEstates.value : '',
          'Data de Nascimento': birthDate,
          'Restrições de Crédito': creditRestrictions.length,
        })

        mixpanel.identify(id)
      }
    }
  }, [fetchUser, fetchContracts])

  return {
    identify,
  }
}

export { trackEvent, useIdentifyUser }

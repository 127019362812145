import { isSome, Option } from 'fp-ts/lib/Option'
import { theme } from 'lib'
import { Typography } from 'ui'
import * as S from './RecommendedDocuments.styled'

type Props = {
  label: string
  description: Option<string>
  recommended: boolean
}

const DocumentItem = ({ label, description, recommended }: Props) => (
  <S.ListItem>
    {recommended && (
      <S.RecommendedBadge variant='outlined'>RECOMENDADO</S.RecommendedBadge>
    )}
    <Typography>{label}</Typography>
    {isSome(description) && (
      <Typography color={theme.colors.gray2}>{description.value}</Typography>
    )}
  </S.ListItem>
)

export { DocumentItem }

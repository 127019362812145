import { media, theme } from 'lib'
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  html {
    font-size: 10px;
  }

  * {
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  ${media.greaterThan('tablet')`
    body {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e8eaff;
    }

    #root {
      max-width: ${theme.constants.wrapperMaxWidth};
      margin: 0 auto;
      overflow: auto;
      background: ${theme.colors.white};
      position: relative;
      box-shadow: 2px 1px 11px 0px #2b35ce12;
    }

    ::-webkit-scrollbar {
      width: .8rem;
    }

    ::-webkit-scrollbar-track {
      border-radius: ${theme.radius.medium};
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.gray2};
      opacity: 0.8;
      border-radius: ${theme.radius.medium};
    }
  `}
`

export { GlobalStyles }

import { Children, cloneElement } from 'react'
import * as S from './Timeline.styled'
import { Custom, TimelineItemProps, TimelineProps } from './Timeline.types'

const Timeline = ({ children, orientation = 'horizontal' }: TimelineProps) => (
  <S.Wrapper orientation={orientation} data-testid='timeline'>
    {Children.map(children, (child) =>
      cloneElement(child, {
        ...child.props,
        orientation,
      }),
    )}
  </S.Wrapper>
)

const TimelineItem = ({
  children,
  orientation,
  variant,
}: TimelineItemProps) => (
  <S.Item orientation={orientation} variant={variant}>
    <div className='timeline-tail'>
      <span />
    </div>
    <S.TimelineChildren>{children}</S.TimelineChildren>
  </S.Item>
)

Timeline.Item = TimelineItem
Timeline.Custom = Custom

export { Timeline }

import styled from 'styled-components'
import MdiIcon from '@mdi/react'
import { Typography } from 'ui/Typography'
import { theme } from 'lib'

const Wrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: ${theme.spacing[4]} ${theme.spacing[2]};
`

const Heading = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing[1]};
`

const Title = styled(Typography)`
  font-weight: 600;
  margin-left: ${theme.spacing[1]};
`

const Text = styled(Typography)`
  font-size: 1.2rem;
  color: ${theme.colors.gray2};
`

const InputFile = styled.input`
  display: none;
`

const Icon = styled(MdiIcon)`
  transform: rotate(-90deg);
`

export { Wrapper, Heading, Title, Text, InputFile, Icon }

import Loader from 'react-content-loader'
import { theme } from 'lib'
import Box from '@material-ui/core/Box'

const Texts = () => (
  <Loader
    speed={2}
    width={100}
    height={86}
    backgroundColor={theme.colors.loaderBackground}
    foregroundColor={theme.colors.loaderForeground}
    style={{ width: '100%', height: '86px' }}
  >
    <rect
      x='0'
      y='0'
      rx={theme.radius.small}
      ry={theme.radius.small}
      width='70%'
      height='3.5rem'
    />
    <rect
      x='0'
      y='46'
      rx={theme.radius.small}
      ry={theme.radius.large}
      width='100%'
      height='3.5rem'
    />
  </Loader>
)

const Timeline = () => (
  <Loader
    speed={2}
    width={100}
    height={500}
    backgroundColor={theme.colors.loaderBackground}
    foregroundColor={theme.colors.loaderForeground}
    style={{ width: '100%', height: '500' }}
  >
    <rect
      x='0'
      y='0'
      rx={theme.radius.small}
      ry={theme.radius.small}
      width='100%'
      height='50.0rem'
    />
  </Loader>
)

export const ContentLoader = () => (
  <div data-testid='timeline-content-loader'>
    <Box mb={5}>
      <Texts />
    </Box>
    <Timeline />
  </div>
)

import styled from 'styled-components'
import { motion } from 'framer-motion'
import { media, theme } from 'lib'
import { Link } from 'react-router-dom'
import { StyledNavLinkProps } from './BottomNav.types'

export const Wrapper = styled(motion.div)`
  position: fixed;
  bottom: 0;
  background: ${theme.colors.blackBlue};
  height: ${theme.constants.bottomNavHeight};
  width: 100%;
  border-radius: ${theme.radius.xLarge} ${theme.radius.xLarge} 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing[1]} ${theme.spacing[3]};
  z-index: ${theme.zIndex.aboveAll};
  font-family: ${theme.fontFamily};
  text-align: center;
  max-width: ${theme.constants.wrapperMaxWidth};
  ${media.lessThan('tablet')`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
  `}

  ${media.greaterThan('tablet')`
    max-width: ${theme.constants.wrapperMaxWidth};
    left: 50%;
    margin-left: calc(-${theme.constants.wrapperMaxWidth} / 2 );
  `}
`
export const Button = styled.button<StyledNavLinkProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  opacity: ${(p) => (p.active === 'true' ? 1 : 0.65)};
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 10px;
  font-family: ${theme.fontFamily};
  line-height: 1.2rem;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

export const NavLink = styled(Link)<StyledNavLinkProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  line-height: 1.2rem;
  color: white;
  opacity: ${(p) => (p.active === 'true' ? 1 : 0.65)};
`

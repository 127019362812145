/* eslint-disable @typescript-eslint/no-explicit-any */
import * as R from 'ramda'
import { pipe } from 'fp-ts/lib/function'
import { map, fromNullable } from 'fp-ts/lib/Option'
import {
  decimalFromInt,
  intFromString,
  monthsFromYears,
  yearsFromMonths,
} from 'lib/formatters/formatters'
import {
  ContractRaw,
  SimulationRaw,
  Contract,
  ProponentDataResponse,
  Simulation,
  ContractDataResponse,
  ProponentRaw,
  Proponent,
  PropertyRaw,
  Property,
  CreditRestrictionRaw,
  CreditRestriction,
  Nullable,
  SimulationParamsRaw,
  SimulationParams,
  DynamicSimulationDataResponse,
  DynamicSimulationRaw,
  DynamicSimulation,
  NewSimulationRaw,
  NewSimulation,
  SimulationParamsBody,
} from 'types'
import { parseISO } from 'date-fns'

export const normalizeProponentResponse = (
  result: Nullable<ProponentDataResponse>,
) =>
  pipe(
    result,
    fromNullable,
    map((result) => result.data),
    map(normalizeProponent),
  )

export const normalizeContractResponse = (
  result: Nullable<ContractDataResponse>,
) =>
  pipe(
    result,
    fromNullable,
    map((result) => result.data),
    map(normalizeContract),
  )

export const normalizeContract = (contract: ContractRaw): Contract =>
  pipe(
    contract,
    R.omit([
      'purchaseSaleValueInCents',
      'debtBalanceInCents',
      'property',
      'simulations',
      'proponents',
    ]),
    (contractValues) => ({
      ...contractValues,
      purchaseSaleValue: decimalFromInt(contract.purchaseSaleValueInCents),
      debtBalance: decimalFromInt(contract.debtBalanceInCents),
      property: normalizeProperty(contract.property),
      simulations: contract.simulations.map(normalizeSimulation),
      proponents: contract.proponents.map(normalizeProponent),
      status: hasProponentWithCreditRestrictions(contract)
        ? 'PRE_ANALYSIS'
        : 'DOCUMENTATION',
    }),
  )

const hasProponentWithCreditRestrictions = (contract: ContractRaw) =>
  contract.proponents.some(
    (proponent) => proponent.creditRestrictions.length > 0,
  )

const normalizeSimulation = (simulation: SimulationRaw): Simulation =>
  pipe(
    simulation,
    R.omit([
      'finalInstallmentsInCents',
      'interestRateWithDecimals',
      'loanAmountInCents',
    ]),
    R.mergeRight({
      finalInstallments: decimalFromInt(simulation.finalInstallmentsInCents),
      interestRate: decimalFromInt(simulation.interestRateWithDecimals),
      loanAmount: decimalFromInt(simulation.loanAmountInCents),
    }),
  )

const normalizeProponent = (proponent: ProponentRaw): Proponent =>
  pipe(
    proponent,
    R.omit(['birthDate', 'creditRestrictions']),
    R.mergeRight({
      birthDate: parseISO(proponent.birthDate),
      creditRestrictions: proponent.creditRestrictions.map(
        normalizeCreditRestriction,
      ),
    }),
  )

const normalizeProperty = (property: PropertyRaw): Property =>
  pipe(
    property,
    R.omit(['areaWithDecimals']),
    R.mergeRight({
      area: decimalFromInt(property.areaWithDecimals),
    }),
  )

const normalizeCreditRestriction = (
  creditRestriction: CreditRestrictionRaw,
): CreditRestriction =>
  pipe(
    creditRestriction,
    R.omit(['valueInCents', 'occurenceDate']),
    R.mergeRight({
      value: decimalFromInt(creditRestriction.valueInCents),
      occurenceDate: parseISO(creditRestriction.occurenceDate),
    }),
  )

export const normalizeSimulationParams = (
  simulationParams: SimulationParamsRaw,
): SimulationParams =>
  pipe(
    simulationParams,
    R.omit([
      'amortizationInCents',
      'incomePrimaryInCents',
      'paymentDeadlineInMonths',
    ]),
    R.mergeRight({
      amortization: decimalFromInt(simulationParams?.amortizationInCents),
      incomePrimary: decimalFromInt(simulationParams?.incomePrimaryInCents),
      paymentDeadline: yearsFromMonths(
        simulationParams?.paymentDeadlineInMonths,
      ),
    }),
  )

export const normalizeDynamicSimulationResponse = (
  result: Nullable<DynamicSimulationDataResponse>,
) =>
  pipe(
    result,
    fromNullable,
    map((result) => result.data),
    map(normalizeDynamicSimulation),
  )

export const normalizeDynamicSimulation = (
  data: DynamicSimulationRaw,
): DynamicSimulation =>
  pipe(
    data,
    R.omit([
      'amortizationInCents',
      'propertyValueToBePaidInCents',
      'simulations',
      'totalPreApprovedValueInCents',
    ]),
    (simulationValues) => ({
      ...simulationValues,
      amortization: decimalFromInt(data.amortizationInCents),
      notorialCosts: decimalFromInt(data.notorialCostsInCents),
      propertyValueToBePaid: decimalFromInt(data.propertyValueToBePaidInCents),
      simulations: data.simulations.map(normalizeNewSimulation),
      totalPreApprovedValue: decimalFromInt(data.totalPreApprovedValueInCents),
    }),
  )

const normalizeNewSimulation = (simulation: NewSimulationRaw): NewSimulation =>
  pipe(
    simulation,
    R.omit([
      'cetWithDecimals',
      'firstInstallmentInCents',
      'interestRateWithDecimals',
      'lastInstallmentInCents',
      'loanTotalValueInCents',
      'maxDebtCapacityInCents',
    ]),
    R.mergeRight({
      cet: decimalFromInt(simulation.cetWithDecimals),
      firstInstallment: decimalFromInt(simulation.firstInstallmentInCents),
      interestRate: decimalFromInt(simulation.interestRateWithDecimals),
      lastInstallment: decimalFromInt(simulation.lastInstallmentInCents),
      loanTotalValue: decimalFromInt(simulation.loanTotalValueInCents),
      maxDebtCapacity: decimalFromInt(simulation.maxDebtCapacityInCents),
    }),
  )

export const normalizeSimulationParamsBody = (
  simulationParams: SimulationParams,
): SimulationParamsBody =>
  pipe(
    simulationParams,
    R.omit([
      'id',
      'contracId',
      'amortization',
      'incomePrimary',
      'paymentDeadline',
      'includeNotorialCosts',
    ]),
    R.mergeRight({
      amortization_in_cents: intFromString(simulationParams?.amortization),
      income_primary_in_cents: intFromString(simulationParams?.incomePrimary),
      payment_deadline_in_months: monthsFromYears(
        simulationParams?.paymentDeadline,
      ),
      include_notorial_costs: simulationParams?.includeNotorialCosts,
    }),
  )

import Icon from '@mdi/react'
import styled, { css } from 'styled-components'
import { theme } from 'lib'
import { Link as LinkRouter } from 'react-router-dom'

import { Kind, StyledProps, Variant } from './Button.types'

export const colorsByVariant = {
  primary: theme.colors.primary,
  warning: theme.colors.orange,
}

export const getIconColor = (variant: Variant, kind: Kind) => {
  if (kind === 'link') return colorsByVariant[variant]

  if (kind === 'outline') return theme.colors.white

  if (kind === 'default') return theme.colors.white
}

export const disabledColorByVariant = {
  primary: theme.colors.gray1,
  warning: theme.colors.gray1,
}

const buttonMixin = ({ block, variant, kind }: StyledProps) => css`
  display: ${block ? 'flex' : 'inline-flex'};
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  outline: 0;
  box-shadow: 0;
  border: 0;
  max-width: 100%;
  height: 3.6rem;
  border-radius: 2.5rem;
  width: ${block ? '100%' : 'auto'};
  font-family: ${theme.fontFamily};
  font-weight: 600;
  font-size: 1.4rem;
  text-decoration: none;

  ${kind === 'default' &&
  css`
    padding: 0 ${theme.spacing[3]};
    background: ${colorsByVariant[variant]};
    color: ${theme.colors.white};
    &:disabled {
      background: ${disabledColorByVariant[variant]};
    }
  `};

  ${kind === 'outline' &&
  css`
    padding: 0 ${theme.spacing[3]};
    background: transparent;
    color: ${colorsByVariant[variant]};
    border: 1px solid ${colorsByVariant[variant]};
    &:disabled {
      background: ${disabledColorByVariant[variant]};
    }
    cursor: pointer;
  `};

  ${kind === 'link' &&
  css`
    background: transparent;
    color: ${colorsByVariant[variant]};
    cursor: pointer;
  `};
`
export const Link = styled(LinkRouter)<StyledProps>`
  ${buttonMixin}
`

export const ExternalLink = styled.a<StyledProps>`
  ${buttonMixin}
`

export const Button = styled.button<StyledProps>`
  ${buttonMixin}
`

export const SuffixIcon = styled(Icon)`
  margin-left: ${theme.spacing[1]};
`

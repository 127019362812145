import { media, theme } from 'lib'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { ContentProps } from './Content.types'

const Content = styled(motion.div)<ContentProps>`
  border-radius: ${theme.radius.xLarge} ${theme.radius.xLarge} 0px 0px;
  box-shadow: -1px -1px 2px rgba(53, 63, 223, 0.04);
  background: ${theme.colors.white};
  position: relative;
  top: ${(p) => (p.floating ? '-2rem' : '0')};
  overflow: auto;
  padding: ${theme.spacing[4]} ${theme.spacing[4]} ${theme.spacing[5]}
    ${theme.spacing[4]};
  ${media.lessThan('smallPhone')`
    padding: ${theme.spacing[5]} ${theme.spacing[3]} ${theme.spacing[5]} ${theme.spacing[3]};
`}
`

export { Content }

import { AnimatePresence, motion } from 'framer-motion'
import { fadeIn } from 'lib'
import { useState } from 'react'
import { ToggleVisibility, Typography } from 'ui'
import { Disclaimer } from 'ui/Disclaimer'

import Box from '@material-ui/core/Box'
import { Restriction } from '../Restriction'
import { PreAnalysisRestriction } from '../types'
import * as S from './RestrictionsList.styled'

type Props = {
  title: string
  disclaimer: string
  restrictions: PreAnalysisRestriction[]
  animationDelay: number
  testId: string
}

const RestrictionsList = ({
  disclaimer,
  title,
  restrictions,
  animationDelay: delay,
  testId,
}: Props) => {
  const [visible, setVisible] = useState(true)
  const toggle = () => setVisible(!visible)

  if (restrictions.length > 0) {
    return (
      <Box data-testid={testId} mb={7}>
        <motion.div
          animate='visible'
          initial='hidden'
          variants={fadeIn({ delay: delay * 0.1 })}
        >
          <S.Heading mb={2}>
            <S.Title>
              <Typography variant='h2'>{title}</Typography>
              <AnimatePresence>
                {visible && (
                  <S.Badge
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {restrictions.length}
                  </S.Badge>
                )}
              </AnimatePresence>
            </S.Title>
            <ToggleVisibility onClick={toggle} visible={visible} />
          </S.Heading>
        </motion.div>

        {restrictions.map((restriction, id) => (
          <Restriction
            animationDelay={id * delay}
            showValues={visible}
            key={id}
            name={restriction.name}
            value={restriction.value}
            proponentName={restriction.proponentName}
          />
        ))}
        <Box mt={4}>
          <Disclaimer message={disclaimer} />
        </Box>
      </Box>
    )
  }

  return null
}

export { RestrictionsList }

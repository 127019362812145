import { lazy, Suspense } from 'react'
import { ThemeProvider } from 'styled-components'
import {
  ErrorBoundary,
  GlobalStyles,
  ScrollToTop,
  Spinner,
  Copyright,
} from 'ui'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { queryClient } from 'client'
import { muiTheme, SessionManager, theme } from 'lib'
import { NotFound, Home } from 'pages'
import { QueryClientProvider } from 'react-query'
import {
  CONFIRM_PHONENUMBER,
  CONTRACT,
  DOCUMENTS,
  EXPIRED_SESSION,
  UPLOAD_DOCUMENTS,
} from 'routes'
import { NavigationProvider } from 'lib/navigation'
import { MuiThemeProvider } from '@material-ui/core'
import { BottomNav } from 'ui/BottomNav'

const Contract = lazy(() => import('./pages/Contract'))
const ExpiredSession = lazy(() => import('./pages/ExpiredSession'))
const Documents = lazy(() => import('./pages/Documents'))
const UploadDocuments = lazy(() => import('./pages/UploadDocuments'))
const ConfirmPhoneNumber = lazy(() => import('./pages/ConfirmPhoneNumber'))

const App = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <NavigationProvider>
          <QueryClientProvider client={queryClient}>
            <MuiThemeProvider theme={muiTheme}>
              <ThemeProvider theme={theme}>
                <GlobalStyles />
                <SessionManager />
                <ErrorBoundary>
                  <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path={CONTRACT} component={Contract} />
                    <Route
                      path={CONFIRM_PHONENUMBER}
                      component={ConfirmPhoneNumber}
                    />
                    <Route path={EXPIRED_SESSION} component={ExpiredSession} />
                    <Route exact path={DOCUMENTS} component={Documents} />
                    <Route
                      path={UPLOAD_DOCUMENTS}
                      component={UploadDocuments}
                    />
                    <Route component={NotFound} />
                  </Switch>
                  <BottomNav />
                  <Copyright />
                  <ScrollToTop />
                </ErrorBoundary>
              </ThemeProvider>
            </MuiThemeProvider>
          </QueryClientProvider>
        </NavigationProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export default App

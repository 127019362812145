import { motion } from 'framer-motion'
import { theme } from 'lib'
import styled from 'styled-components'
import { Badge } from 'ui'

const DocumentsList = styled.ul`
  margin: ${theme.spacing[2]} 0 0 0;
  padding: 0;
`

const DocumentItem = motion(styled.li`
  list-style: none;
  margin-bottom: ${theme.spacing[2]};
  position: relative;
`)

const StatusBadge = styled(Badge)`
  position: absolute;
  top: 50%;
  right: -0.75rem;
  transform: translateY(-50%);
`

export { DocumentItem, DocumentsList, StatusBadge }

import Icon from '@mdi/react'
import { AnimatePresence } from 'framer-motion'
import { mdiClose } from '@mdi/js'

import { theme } from 'lib'
import { ReactNode } from 'react'
import * as S from './Dialog.styled'

type Props = {
  visible: boolean
  onClose?: () => void
  children: ReactNode
}

const Dialog = ({ visible, onClose, children }: Props) => {
  return (
    <AnimatePresence>
      {visible && (
        <S.Overlay
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
        >
          <S.Dialog
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
          >
            {onClose && (
              <S.CloseButton
                data-testid='dialog-close-button'
                onClick={onClose}
              >
                <Icon
                  path={mdiClose}
                  color={theme.colors.black}
                  size='2.0rem'
                />
              </S.CloseButton>
            )}
            {children}
          </S.Dialog>
        </S.Overlay>
      )}
    </AnimatePresence>
  )
}

export { Dialog }

import { useDocuments } from 'client'
import { pipe } from 'fp-ts/lib/function'
import { groupBy, NonEmptyArray } from 'fp-ts/lib/NonEmptyArray'
import { fold, fromNullable, map, none as None } from 'fp-ts/lib/Option'
import {
  Document,
  DocumentCategory,
  DocumentGroup,
  DocumentGroupStatus,
  DocumentStatus,
} from 'types'

const useGroupedDocuments = () => {
  const { documents, isLoading } = useDocuments({
    category: None,
  })

  return {
    isLoading,
    groups: map(groupDocumentsByCategory)(documents),
  }
}

export const groupDocumentsByCategory = (documents: Document[]) =>
  pipe(
    documents,
    groupBy((document) => document.category),
    (group) => [
      createGroup(group, 'IDENTIFICACAO'),
      createGroup(group, 'RENDA'),
      createGroup(group, 'RESIDENCIA'),
      createGroup(group, 'ESTADO_CIVIL'),
    ],
  )

export const createGroup = (
  group: Record<string, NonEmptyArray<Document>>,
  category: DocumentCategory,
): DocumentGroup =>
  pipe(
    group[category],
    fromNullable,
    fold(
      () => ({
        category,
        status: { tag: 'WAITING' },
        amountOfDocuments: 0,
      }),
      (documents) => ({
        category,
        status: getGroupStatus(documents),
        amountOfDocuments: documents.length,
      }),
    ),
  )

const statusEq = (status: DocumentStatus) => (document: Document) =>
  document.status === status

const getGroupStatus = (
  documents: NonEmptyArray<Document>,
): DocumentGroupStatus => {
  if (documents.some(statusEq('REFUSED'))) {
    return {
      tag: 'REFUSED',
      amountOfRefused: documents.filter(statusEq('REFUSED')).length,
    }
  }

  if (documents.some(statusEq('PENDING'))) {
    return { tag: 'PENDING' }
  }

  if (!documents.some((d) => d.status !== 'APPROVED')) {
    return { tag: 'APPROVED' }
  }

  return { tag: 'WAITING' }
}

export { useGroupedDocuments }

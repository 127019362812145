import { post, useDocuments } from 'client'
import { filterWithIndex, last } from 'fp-ts/lib/Array'
import { pipe } from 'fp-ts/lib/function'
import {
  fromNullable,
  isSome,
  Option,
  some as Some,
  none as None,
  fold,
} from 'fp-ts/lib/Option'
import { trackEvent } from 'lib'
import { concat, split } from 'ramda'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { DOCUMENTS } from 'routes'
import { Document, DocumentCategory, FileType, isValidCategory } from 'types'
import { LocalFile, LocalFileStatus, UploadStatus } from '../types'

type PreviewModal = {
  visible: boolean
  document: Option<Document>
}
const useUploadDocuments = () => {
  const history = useHistory()
  const [previewModal, setPreviewModal] = useState<PreviewModal>({
    visible: false,
    document: None,
  })
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('initial')
  const [files, setFiles] = useState<LocalFile[]>([])
  const { category } = useParams<{ category: string }>()
  const { documents, refetch, isLoading } = useDocuments({
    category: Some(categoryFromString(category)),
  })

  const openPreview = useCallback((document: Document) => {
    setPreviewModal({ visible: true, document: Some(document) })
  }, [])

  const closePreview = useCallback(() => {
    setPreviewModal({ visible: false, document: None })
  }, [])

  const appendFile = useCallback(async (maybeFiles: Option<FileList>) => {
    if (isSome(maybeFiles)) {
      const files = await Promise.all(normalizeFiles(maybeFiles.value))
      setFiles(concat(files))
    }
  }, [])

  const removeFile = useCallback((indexToRemove) => {
    setFiles(filterWithIndex((index) => index !== indexToRemove))
  }, [])

  const closeSuccessDialog = useCallback(() => {
    setUploadStatus('initial')
    setFiles([])
  }, [])

  const closeErrorDialog = useCallback(() => {
    setUploadStatus('initial')
  }, [])

  const uploadFiles = useCallback(async () => {
    setUploadStatus('uploading')
    setFiles(setFileStatus('sending'))

    const uploads = files.map(
      async (file, index): Promise<LocalFileStatus> => {
        const formData = new FormData()
        trackEvent('Documento Enviado', {
          'File Category': category,
          'File Name': file.name,
          'File Type': file.fileType,
        })
        formData.append('category', category)
        formData.append('file', file.binaryFile, file.name)

        return post('/document_uploads', formData)
          .then(() => {
            setFiles(updateStatusWithIndex('success', index))
            return 'success' as LocalFileStatus
          })
          .catch(() => {
            setFiles(updateStatusWithIndex('error', index))
            return 'error' as LocalFileStatus
          })
      },
    )

    const uploadResult = await Promise.all(uploads)

    const uploadStatus = uploadResult.some((status) => status === 'error')
      ? 'uploadError'
      : 'success'

    setUploadStatus(uploadStatus)
    setFiles(removeUploadedFiles)
    refetch()
  }, [files])

  useEffect(() => {
    if (!isValidCategory(category)) {
      history.push(DOCUMENTS)
    }
  }, [category])

  return {
    appendFile,
    removeFile,
    uploadFiles,
    openPreview,
    closeSuccessDialog,
    closeErrorDialog,
    documents,
    files,
    uploadStatus,
    isLoading,
    previewModal,
    closePreview,
    category: categoryFromString(category),
  }
}

const removeUploadedFiles = (files: LocalFile[]) =>
  files.filter((file) => file.status !== 'success')

const updateStatusWithIndex = (status: LocalFileStatus, index: number) => (
  files: LocalFile[],
) =>
  files.map((file, fileIndex) =>
    fileIndex !== index ? file : { ...file, status },
  )

const setFileStatus = (status: LocalFileStatus) => (files: LocalFile[]) =>
  files.map((file) => ({
    ...file,
    status,
  }))

const createThumbnail = (file: globalThis.File): Promise<Option<string>> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.addEventListener('loadend', () => {
      resolve(fromNullable(reader.result as string))
    })

    reader.addEventListener('error', () => reject(new Error('')))

    reader.readAsDataURL(file)
  })

export const fileTypeFromPath = (url: string): FileType =>
  pipe(
    url,
    split('.'),
    last,
    fold(
      () => 'pdf',
      (fileExtension: string) =>
        ['jpg', 'jpeg', 'png'].includes(fileExtension)
          ? (fileExtension as FileType)
          : 'pdf',
    ),
  )

export const normalizeFiles = (files: FileList) =>
  Array.from(files).map(
    async (file): Promise<LocalFile> => ({
      binaryFile: file,
      name: file.name,
      thumbnail: await createThumbnail(file),
      fileType: fileTypeFromPath(file.name),
      status: 'initial',
    }),
  )

const categoryFromString = (category: string) => {
  if (isValidCategory(category)) {
    return category as DocumentCategory
  }

  return 'IDENTIFICACAO'
}

export { useUploadDocuments }

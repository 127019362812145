import { some as Some, none as None } from 'fp-ts/lib/Option'
import { fadeIn, theme } from 'lib'
import { useMemo } from 'react'
import { DocumentCategory } from 'types'
import { Accordion, Typography } from 'ui'
import Box from '@material-ui/core/Box'
import { motion } from 'framer-motion'
import { DocumentItem } from './DocumentItem'

import * as S from './RecommendedDocuments.styled'
import { DocumentsList } from './RecommendedDocuments.types'

type Props = {
  category: DocumentCategory
}

const RecommendedDocuments = ({ category }: Props) => {
  const listOfDocuments = useMemo(
    () =>
      ({
        IDENTIFICACAO: identityDocs,
        ESTADO_CIVIL: maritalStatusDocs,
        RENDA: incomeDocs,
        RESIDENCIA: residenceDocs,
      }[category]),
    [category],
  )
  return (
    <Box>
      <Typography
        animate='visible'
        initial='hidden'
        variants={fadeIn({ delay: 1.0 })}
        variant='h3'
      >
        Quais documentos preciso enviar?
      </Typography>
      <Typography
        animate='visible'
        initial='hidden'
        variants={fadeIn({ delay: 1.0 })}
        color={theme.colors.gray2}
      >
        Envie ao menos um dos documentos listados abaixo.
      </Typography>
      <motion.div
        animate='visible'
        initial='hidden'
        variants={fadeIn({ delay: 1.0 })}
      >
        <Box mt={3}>
          <Typography color={theme.colors.gray2}>
            Imagens com boa qualidade ajudam a tornar a verificação mais rápida!
          </Typography>
        </Box>
      </motion.div>
      <motion.div
        animate='visible'
        initial='hidden'
        variants={fadeIn({ delay: 1.0 })}
      >
        <Box>
          {listOfDocuments.tag === 'simple' && (
            <S.List>
              {listOfDocuments.items.map((document, id) => (
                <DocumentItem
                  key={id}
                  label={document.label}
                  description={document.description}
                  recommended={document.recommended}
                />
              ))}
            </S.List>
          )}
          {listOfDocuments.tag === 'grouped' &&
            listOfDocuments.items.map((group, id) => (
              <Box key={id} mt={3}>
                <Accordion title={group.title}>
                  <S.AccordionList>
                    {group.documents.map((document, documentId) => (
                      <DocumentItem
                        key={documentId}
                        label={document.label}
                        description={document.description}
                        recommended={document.recommended}
                      />
                    ))}
                  </S.AccordionList>
                </Accordion>
              </Box>
            ))}
        </Box>
      </motion.div>
    </Box>
  )
}

export const identityDocs: DocumentsList = {
  tag: 'simple',
  items: [
    {
      label: 'RG ou CNH',
      description: Some('Envie frente e verso separadamente'),
      recommended: true,
    },
    {
      label: 'CPF',
      description: Some(
        'Só precisa enviar se o número não constar na RG ou CNH.',
      ),
      recommended: false,
    },
    {
      label: 'Certidão de Nascimento',
      description: None,
      recommended: false,
    },
  ],
}

export const residenceDocs: DocumentsList = {
  tag: 'simple',
  items: [
    {
      label: 'Conta de luz',
      description: Some('Atualizada, com no máximo 30 dias de emissão.'),
      recommended: true,
    },
    {
      label: 'Conta de telefone',
      description: Some('Atualizada, com no máximo 30 dias de emissão.'),
      recommended: false,
    },
    {
      label: 'Conta de água',
      description: Some('Atualizada, com no máximo 30 dias de emissão.'),
      recommended: false,
    },
  ],
}

export const maritalStatusDocs: DocumentsList = {
  tag: 'grouped',
  items: [
    {
      title: 'Solteiros',
      documents: [
        {
          label: 'Certidão de Nascimento',
          description: None,
          recommended: false,
        },
      ],
    },
    {
      title: 'Casados',
      documents: [
        {
          label: 'Certidão de Casamento',
          description: None,
          recommended: false,
        },
        {
          label: 'RG do(a) cônjuge',
          description: Some('Enviar frente e verso separadamente.'),
          recommended: false,
        },
      ],
    },
    {
      title: 'Separados e Divorciados',
      documents: [
        {
          label: 'Certidão de Casamento',
          description: Some('Precisa conter a averbação do divórcio.'),
          recommended: false,
        },
      ],
    },
    {
      title: 'Viúvo',
      documents: [
        {
          label: 'Certidão de Casamento',
          description: Some(
            'Precisa estar atualizada com a informação do óbito.',
          ),
          recommended: false,
        },
      ],
    },
  ],
}

export const incomeDocs: DocumentsList = {
  tag: 'grouped',
  items: [
    {
      title: 'Assalariados',
      documents: [
        {
          label: 'Holerites',
          description: Some('Enviar os três últimos.'),
          recommended: true,
        },
        {
          label: 'IRPF',
          description: Some(
            'Enviar documento completo relativo ao último exercício, inclusive com o protocolo de entrega.',
          ),
          recommended: false,
        },
      ],
    },
    {
      title: 'Profissionais Liberais e Autônomos',
      documents: [
        {
          label: 'Extratos bancários',
          description: Some(
            'Enviar os três últimos, contendo a identificação do cliente e da conta corrente.',
          ),
          recommended: true,
        },
        {
          label: 'IRPF',
          description: Some(
            'Enviar documento completo relativo ao último exercício, inclusive com o protocolo de entrega.',
          ),
          recommended: false,
        },
        {
          label: 'Contrato de Prestação de Serviços ou Notas Fiscais',
          description: Some('Enviar dos três últimos meses.'),
          recommended: false,
        },
      ],
    },
    {
      title: 'Sócios e Proprietários de Empresa',
      documents: [
        {
          label: 'Cópia do contrato social',
          description: Some(
            'Deve vir acompanhado da última alteração devidamente registrada no órgão competente.',
          ),
          recommended: true,
        },
        {
          label: 'Relação de faturamentos',
          description: Some(
            'Enviar dos 12 últimos meses, em papel timbrado e assinado pelo contador.',
          ),
          recommended: false,
        },
        {
          label: 'IRPF',
          description: Some(
            'Enviar documento completo relativo ao último exercício, inclusive com o protocolo de entrega.',
          ),
          recommended: false,
        },
        {
          label: 'Declaração do Imposto de Renda - PJ',
          description: Some(
            'Enviar cópia completa relativa ao último exercício.',
          ),
          recommended: false,
        },
        {
          label: 'Extratos bancários PF',
          description: Some(
            'Enviar dos três últimos meses, de todas as contas, contendo a identificação do cliente e da conta corrente.',
          ),
          recommended: false,
        },
        {
          label: 'Extratos bancários PJ',
          description: Some(
            'Enviar dos três últimos meses, de todas as contas.',
          ),
          recommended: false,
        },
      ],
    },
    {
      title: 'Aposentados e Pensionistas',
      documents: [
        {
          label: 'IRPF',
          description: Some(
            'Enviar documento completo relativo ao último exercício, inclusive com o protocolo de entrega.',
          ),
          recommended: true,
        },
        {
          label: 'Declaração de Isento - PF',
          description: Some('Para quem se aplicar.'),
          recommended: false,
        },
        {
          label: 'Extrato do INSS',
          description: Some('Enviar cópia relativa ao último mês.'),
          recommended: false,
        },
      ],
    },
    {
      title: 'Renda Proveniente de Aluguel',
      documents: [
        {
          label: 'Recibos de locação',
          description: Some('Enviar cópia relativa aos três últimos meses.'),
          recommended: true,
        },
        {
          label: 'IRPF',
          description: Some(
            'Enviar documento completo relativo ao último exercício, inclusive com o protocolo de entrega.',
          ),
          recommended: false,
        },
        {
          label: 'Extratos bancários',
          description: Some(
            'Enviar os três últimos, contendo a identificação do cliente e da conta corrente.',
          ),
          recommended: false,
        },
      ],
    },
  ],
}

export { RecommendedDocuments }

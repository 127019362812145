import { ReactNode } from 'react'
import { Button, Typography } from 'ui'
import Box from '@material-ui/core/Box'
import { UploadItem } from './UploadItem'

import * as S from './UploadsList.styled'

type Props = {
  children: ReactNode
  amountOfFiles: number
  onUpload: () => void
  isUploading: boolean
}

const UploadsList = ({
  children,
  amountOfFiles,
  onUpload,
  isUploading,
}: Props) => (
  <Box data-testid='uploads-list'>
    <Box mb={2}>
      <Typography>
        {amountOfFiles === 1 && (
          <>
            <strong>{amountOfFiles} documento</strong> pronto para o envio
          </>
        )}
        {amountOfFiles > 1 && (
          <>
            <strong>{amountOfFiles} documentos</strong> prontos para o envio
          </>
        )}
      </Typography>
    </Box>
    <S.List>{children}</S.List>
    <Box justifyContent='center' alignItems='center' display='flex' my={5}>
      <Button disabled={isUploading} onClick={onUpload}>
        Enviar documentos
      </Button>
    </Box>
  </Box>
)

export { UploadsList, UploadItem }

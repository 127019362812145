import styled from 'styled-components'
import { theme } from 'lib'
import { transparentize } from 'polished'

const IconWrapper = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  background: ${transparentize(0.7, theme.colors.blackBlue)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${theme.spacing[2]};
`

const Status = styled.div`
  position: absolute;
  right: -0.8rem;
`

const Heading = styled.div`
  display: flex;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-top: ${theme.spacing[3]};
`

const Footer = styled.div`
  display: flex;
  margin-top: ${theme.spacing[1]};
  justify-content: flex-end;
`

export { IconWrapper, Heading, Footer, Row, Status }

import { get } from 'client'
import { useQuery } from 'react-query'
import { ContractDataResponse, Nullable } from 'types'
import {
  none as None,
  some as Some,
  Option,
  fromNullable,
  map,
} from 'fp-ts/lib/Option'
import { normalizeContractResponse } from 'client/normalizations'
import { pipe } from 'fp-ts/lib/function'
import { DOCUMENTS, PRE_ANALYSIS, SIMULATIONS } from 'routes'
import { useRouteMatch } from 'react-router-dom'
import { TimelineVariant } from 'ui'

export type TimelineStep = {
  title: string
  description: string
  link: Option<{ label: string; href: string }>
  variant: TimelineVariant
}

export const useContract = (contractId: string) => {
  const { url } = useRouteMatch()

  const { data: contractData, isFetching, isError, isLoading } = useQuery({
    queryKey: ['contracts', contractId],
    queryFn: () => get<ContractDataResponse>(`/contracts/${contractId}`),
    retry: false,
    refetchOnWindowFocus: false,
  })

  return {
    isLoading,
    isFetching,
    isError,
    contract: normalizeContractResponse(contractData),
    contractSteps: transformSteps(url, contractData),
  }
}

export const transformSteps = (
  url: string,
  result: Nullable<ContractDataResponse>,
) =>
  pipe(
    result,
    fromNullable,
    map((result) => result.data),
    map((contract) => {
      const hasRestrictions = contract.proponents.some(
        (proponent) => proponent.creditRestrictions.length > 0,
      )
      return createStepList(url, hasRestrictions)
    }),
  )

const createStepList = (
  url: string,
  hasCreditRestrictions: boolean,
): TimelineStep[] => [
  {
    title: 'Pré-Análise',
    description: hasCreditRestrictions
      ? 'Encontramos algumas restrições durante a pré-análise. É importante resolver estas pendências para dar continuidade ao seu financiamento com os bancos.'
      : 'Ótima notícia! Você não tem pendências a serem resolvidas.',
    link: hasCreditRestrictions
      ? Some({
          label: 'Ver pré-análise',
          href: `${url}${PRE_ANALYSIS}`,
        })
      : None,
    variant: hasCreditRestrictions ? 'warning' : 'done',
  },
  {
    title: 'Simulações',
    description:
      'Preparamos algumas simulações para o seu financiamento imobiliário.',
    link: Some({
      label: 'Ver simulações',
      href: `${url}${SIMULATIONS}`,
    }),
    variant: 'done',
  },
  {
    title: 'Documentação',
    description:
      'Acelere seu processo! Envie agora mesmo os seus documentos para a análise de crédito.',
    link: Some({
      label: 'Enviar documentos',
      external: false,
      href: DOCUMENTS,
    }),
    variant: 'active',
  },
  {
    title: 'Análise de Crédito',
    description:
      'Indica que a sua documentação foi enviada para a análise do banco.',
    link: None,
    variant: 'waiting',
  },
  {
    title: 'Análise Jurídica',
    description:
      'Ou seja, o banco está analisando os documentos do comprador, vendedor e do imóvel.',
    link: None,
    variant: 'waiting',
  },
  {
    title: 'Emissão do Contrato',
    description: 'Indica que o contrato de crédito já foi emitido pelo banco.',
    link: None,
    variant: 'waiting',
  },
]

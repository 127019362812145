import { theme } from 'lib'
import { useSetPreviousRoute } from 'lib/navigation'
import { Header, Typography, Wrapper } from 'ui'
import { isSome } from 'fp-ts/lib/Option'

import Box from '@material-ui/core/Box'
import { useGroupedDocuments } from './useGroupedDocuments'
import { CategoryCard } from './CategoryCard'
import { ContentLoader } from './ContentLoader'

const Documents = () => {
  useSetPreviousRoute('/')
  const { groups, isLoading } = useGroupedDocuments()

  return (
    <Wrapper ignoreHeader>
      <Header title='Documentação' />
      <Box mt={4} mb={10} px={3}>
        <Typography variant='h1'>Envio de documentos</Typography>

        <Typography color={theme.colors.gray2}>
          Nesta etapa precisamos que você nos envie alguns documentos para
          análise de crédito. Você também pode acompanhar o processo de
          verificação deles por aqui.
        </Typography>

        {isLoading && (
          <Box my={3}>
            <ContentLoader />
          </Box>
        )}

        {!isLoading && isSome(groups) && (
          <Box my={3} data-testid='documents-container'>
            {groups.value.map((group, id) => (
              <Box mb={3} key={id}>
                <CategoryCard
                  status={group.status}
                  category={group.category}
                  amountOfDocuments={group.amountOfDocuments}
                  key={id}
                  animationId={id}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Wrapper>
  )
}

export default Documents

import { last } from 'fp-ts/lib/Array'
import { isSome } from 'fp-ts/lib/Option'
import {
  ReactNode,
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { useHistory } from 'react-router-dom'

type ContextValue = {
  routes: string[]
  setRoutes: (routes: string[]) => void
}

type Props = {
  children: ReactNode
}

const NavigationContext = createContext<ContextValue>({
  routes: [],
  setRoutes: () => {},
})

export const NavigationProvider = ({ children }: Props) => {
  const [routes, setRoutes] = useState<string[]>([])

  return (
    <NavigationContext.Provider value={{ routes, setRoutes }}>
      {children}
    </NavigationContext.Provider>
  )
}

export const useNavigation = () => {
  const history = useHistory()
  const { routes, setRoutes } = useContext(NavigationContext)

  const goBack = useCallback(() => {
    const route = last(routes)

    if (isSome(route)) {
      history.push(route.value)
      const updatedRoutes = routes.splice(0, routes.length - 1)
      setRoutes(updatedRoutes)
    }
  }, [routes])

  return { goBack }
}

export const useSetPreviousRoute = (route: string) => {
  const context = useContext(NavigationContext)

  useEffect(() => {
    context.setRoutes([...context.routes, route])
  }, [])
}
